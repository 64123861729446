import styled from "styled-components";
import { FormBox } from "../Register/Register.styled";

export const AddUserBtn = styled.div`
    position: absolute;
    bottom: 16px;
    width: calc(100% - 2rem);
    background: white;
    button{
        padding: 1rem 0;
    }
`
export const UserFormBox = styled(FormBox)`
    position: relative;
    padding: 1rem 0;
    margin: 0 auto;
    min-height: calc(100vh - 44px);
    form{
        padding: 0 1rem;
    }
`
export const HealthBox = styled.div`
    border: 1px solid #bababa;
    background: white;
    margin-bottom: 230px;
    padding: 16px;
    border-radius: 4px;
    box-shadow : 5px 10px 10px #eee;
`