import React, {useEffect} from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState } from 'react';
import Btn from '../Btn/Btn';
import { recordActions } from '../../../store/record-slice';
import {basicActions} from '../../../store/basic-slice';
import { userActions } from '../../../store/user-slice';
import Title from '../Title/Title';

const ShoppingCart = () => {
    const [show, setShow] = useState(false);
    const newDate = new Date();
    const currentDate = `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')}`;
    const currentMonth = `${newDate.getFullYear()}-${String(newDate.getMonth() + 1).padStart(2, '0')}`;
    const [year, month] = currentDate.split('-').slice(0, 2);
    const main_user_id = useSelector((state) => state.user.main_user_id);
    const user_id = localStorage.getItem('id');

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    const [shopcart, setShopCart] = useState(true);
    const dispatch = useDispatch();
    const recordFoodList = useSelector((state) => state.record.recordFoodList);
    const main_user_recommend_food = useSelector((state) => state.user.user_recommend_food);
    const main_user_recommend_product = useSelector((state) => state.user.user_recommend_product);

    const handleRemoveFoodlist = (item_id) => {
      dispatch(
          recordActions.recordFoodList({
              recordFoodList: recordFoodList.filter(item => item !== item_id)
          })
      );
    };

    // 현재 날짜의 월 사용자기록 다시 불러오기
    const UserRecord = async() => {
        // AbortController를 생성하고 타임아웃을 30초로 설정합니다.
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 30000);
        let errorCaught = false; // 오류를 캐치했는지 여부를 추적하는 변수

        try{
            const response = await axios.post('/record/api/v1/user_record_get_month/', {
                user_card_id: main_user_id,
                year: year,
                month: month
            },
            {headers: {
                "Content-Type": "application/json",},
            signal: controller.signal, // AbortController를 fetch에 전달합니다.
            });
            // 타임아웃 전에 응답이 왔다면 clearTimeout을 호출하여 타임아웃을 취소합니다.
            clearTimeout(timeoutId);

            // 성공적인 응답 처리
            dispatch(
                recordActions.recordget({
                    month: currentMonth,
                    data: response.data
                })
            );
              //사용자 추천 푸드 호출, 추천식품 변경
              try{
                const response0 = await axios.post('/food/api/v1/user_recommend_food/',{
                    user_id: user_id,
                    user_card:  main_user_id,
                },
                {headers: {
                    "Content-Type": "application/json",}});
                //사용자 추천 푸드 리스트 히스토리 호출
                try{
                    const response2 = await axios.post('/food/api/v1/user_recommend_food_history/', {
                        is_active: true,
                        user_id: user_id,
                        user_card:  main_user_id,
                    },
                    {headers: {
                        "Content-Type": "application/json",}});

                    dispatch(
                        userActions.setuserrecommendfood({
                            user_recommend_food: response2.data.details
                        })
                    )
                    // localstorage에 저장할 데이터
                    const result = {
                        user_id: user_id,
                        user_card: main_user_id,
                        data: response2.data.details,
                    };
                    //로컬스토리지 저장
                    localStorage.setItem('myFoodData', JSON.stringify(result));

                    //사용자 추천 제품 호출
                    try{
                        const response3 = await axios.post('/food/api/v1/user_recommend_product/', {
                            user_id: user_id,
                            user_card: main_user_id,
                        },
                        {headers: {
                            "Content-Type": "application/json",}});

                        dispatch(
                            userActions.setuserrecommendproduct({
                                user_recommend_product: response3.data.detail
                            })
                        );
                        // localstorage에 저장할 데이터
                        const result2 = {
                            user_id: user_id,
                            user_card: main_user_id,
                            data: response3.data.detail,
                        };
                        //로컬스토리지 저장
                        localStorage.setItem('myproductData', JSON.stringify(result2));

                    }catch(error){
                        alert('제품 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                    }
                }catch(error){
                    alert('식품 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                }
              }catch(error){
                alert('추천식품 변경 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
              }

        }catch(error){
            // 오류를 캐치한 경우, 중복오류 메시지가 뜨지 않도록
            if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                errorCaught = true; // 오류를 캐치했음을 표시
                alert('사용자 기록 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
            }
        }finally{
            dispatch(
                basicActions.setisLoading({
                    isLoading: false
                }));
            window.location.reload();
        }
    };

    const UserRecordSet = async () => { //사용자 기록 저장 API호출
      try{
          const response = await axios.post('/record/api/v1/user_record_set/', {
              user_card_id: main_user_id,
              record_date: currentDate,
              foods: recordFoodList
          },
          {headers: {
              "Content-Type": "application/json",}});
            dispatch(
              recordActions.recordFoodListClear(),
            )
          // 데이터 저장 후 바로 불러오지 않고, 일정 시간(예: 1초)이 지난 후에 UserRecord 함수 호출
          setTimeout(() => {
              UserRecord();
          }, 1000); // 1초 후에 호출되도록 설정

      }catch(error){
          alert('기록 저장 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
      };
    };

    const handleSave = () =>{
      if (window.confirm("기록을 저장하시겠습니까? 기록 저장 후 추천식품이 변경됩니다.") == true){ 
          dispatch(
              basicActions.setisLoading({
                  isLoading: true
              }));

          UserRecordSet();
          //true는 확인버튼을 눌렀을 때 코드 작성
          // console.log('완료됨')
        }else{
          // false는 취소버튼을 눌렀을 때, 취소됨
          // console.log("취소되었습니다");
        }
  };
  
    return(
        <>
        <Button variant="primary" 
        onClick={toggleShow}
          style={{background:'transparent', border:'none', display:'flex',alignItems:'center',position:'relative'}}>
          <span class="material-symbols-outlined" style={{fontSize: '1.4rem'}}>shopping_cart</span>
          {recordFoodList.length>0?
            <Title style={{background:'cornflowerblue',color:'white',borderRadius:'100%', width: '20px',height:'20px', position:'absolute',top:0,right:'4px'}}
              className="thin text-center w_800" value={recordFoodList.length} />
          :null}
        </Button>
        <Offcanvas show={show} onHide={handleClose} placement='end' scroll={false}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>장바구니</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {main_user_recommend_food?.map((food,key)=>(
              <div key={key}>
              {recordFoodList?.map((item,index)=>(
                    <>
                    {food.food_id == item?
                      <div className='mb-2' key={index} style={{padding: '8px 0px'}}>
                          <div style={{display:'flex', justifyContent:'space-between',alignItems:'center'}}>
                            <p>
                              <Title className="semibold text-left mb-1" value={food.food_name} />
                              {
                                  // main_user_recommend_product 중복 제거
                                  [...new Map(
                                      main_user_recommend_product.map((m) => [`${m.rec_product_id}_${m.rec_product_name}`, m]),
                                  ).values()]?.map((product, index2) => {
                                      if(product.food_names.includes(food.food_name)){
                                          return(
                                              <Title className="light text-left" value={product.rec_product_name} />
                                          )
                                      }
                                  })
                              }
                            </p>
                            <Btn variant="secondary" onClick={()=>handleRemoveFoodlist(item)} style={{minWidth:'auto', padding:'4px'}} size="sm" className="grayborder" value="X" />
                          </div>
                      </div>
                    :null}
                    </>
                  ))}
              </div>
            ))}
            <Btn variant="secondary" onClick={handleSave} size="lg" className="cornflowerblue full primary" value="기록하기" />
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
}

export default ShoppingCart;