import React, { useEffect, useRef, useState } from "react";
import * as d3 from 'd3';
import './Bubble.css';
import { useSelector } from "react-redux";

const Bubble = (props) => {
    const chartRef = useRef(null);
    const [isGraphGenerated, setIsGraphGenerated] = useState(false); // 상태 추가
    const [uniqueNuList, setUniqueNuList] = useState([]);
    const [uniqueNuCal, setUniqueNuCal] = useState([{'식이섬유': 4,'DHA+EPA' : 3,'마그네슘':2,'비타민B6':1,'비타민B12':2,'엽산':3}]);
    const [json_children, setJsonChildren] = useState([{name:'test', value: 0, color:'darkgreen'}])
    const targetDate = useSelector((state) => state.analysis.targetdate);
    const targetMonth = useSelector((state) => state.analysis.targetmonth);
    const targetStartDate = useSelector((state) => state.analysis.targetstartdate);
    const targetEndDate = useSelector((state) => state.analysis.targetenddate);
    const targetRangeDate = `${targetStartDate} ~ ${targetEndDate}`;


    useEffect(()=>{
        // 이전에 생성된 SVG 요소 제거
        d3.select(chartRef.current).selectAll('svg').remove();
        setIsGraphGenerated(false);
    },[props.targetDate, targetDate,targetRangeDate, uniqueNuList, json_children, props.check]);

    useEffect(()=>{
        try{
            setUniqueNuList(props.analysisData[props.targetDate][0].all_nutro_kinds);
            props.setUniqueNuList(props.analysisData[props.targetDate][0].all_nutro_kinds);
            // 빈 객체를 생성합니다.
            const counts = {};

            // 리스트를 순회하면서 각 항목의 등장 횟수를 세어서 counts 객체에 저장합니다.
            uniqueNuList.forEach(item => {
                // 현재 항목이 counts 객체에 이미 존재하는지 확인합니다.
                if (counts[item]) {
                // 이미 존재한다면 등장 횟수를 증가시킵니다.
                counts[item]++;
                } else {
                // 존재하지 않는다면 새로운 항목으로 추가하고 등장 횟수를 1로 초기화합니다.
                counts[item] = 1;
                }
            });

            // 결과를 원하는 형식의 객체로 변환합니다.
            const result = {};
            for (const [key, value] of Object.entries(counts)) {
                result[key] = value;
            }
            // 리스트로 감싸기
            const resultList = [result];
            setUniqueNuCal(resultList);
        }catch{}
    },[props.analysisData,props.targetDate,uniqueNuList])

    useEffect(()=>{
        // 색상을 가져오는 함수 예시
        function getColorForName(name) {
            // name에 따라 색상을 결정하는 로직 구현
            if (name === '식이섬유') {
                return 'rgb(255 96 96 / 60%)';
            } else if (name === 'DHA+EPA') {
                return 'rgb(20 187 213 / 100%)';
            } else if (name === '비타민A') {
                return 'rgb(139 152 255 / 100%)';
            } else if (name === '트립토판') {
                return 'rgb(145 203 253 / 100%)';
            } else if (name === '엽산') {
                return 'rgb(178 226 106 / 100%)';
            } else if (name === '비타민D') {
                return 'rgba(255, 206, 86, 0.2)';
            } else if (name === '마그네슘') {
                return 'rgb(255 216 106 / 100%)';
            }else{
                return 'darkgray';
            }
            // 다른 영양소에 대한 색상 추가
        }

        const uniquechildren = uniqueNuList?.map((name) => {
            const valueObject = uniqueNuCal.find(obj => obj[name] !== undefined);
            const value = valueObject ? valueObject[name] : 0;
            const color = getColorForName(name);
            return {
                'name': name,
                'value': value,
                'color': color
            };
        })
        setJsonChildren(uniquechildren);
        // console.log(uniquechildren,'uniquechildren!');
    },[uniqueNuCal, uniqueNuList])

    useEffect(() => {
        try{
        if (json_children[0].value !== 0 && json_children[0].name !== 'test' &&! isGraphGenerated) {
            var json = {
                'children': json_children
            }
                // 'children': [
                //     {'name': '식이섬유', 'value': 10, color:'orange'},
                //     {'name': 'Oranges', 'value': 44, color:'red'},
                //     {'name': 'Kiwis', 'value': 65, color:'blue'},
                //     {'name': 'Bananas', 'value': 39, color:'orange'},
                //     {'name': 'Pears', 'value': 10, color:'green'},
                //     {'name': 'Satsumas', 'value': 25, color:'orange'},
                //     {'name': 'Pineapples', 'value': 30, color:'orange'}
                // ]

            var diameter = 370;
            
            var colorScale = d3.scaleLinear()
                .domain([0, d3.max(json.children, function(d) {
                    return d.value;
                })])
                .range(["rgb(46, 73, 123)", "rgb(71, 187, 94)"]);
            
            var bubble = d3.pack()
                .size([diameter, diameter])
                .padding(3);
            
            var margin = {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }

            // ------width animation 추가-----------------
            //------원본---
            // var svg = d3.select(chartRef.current).append('svg')
            //     .attr('viewBox', '0 0 ' + (diameter + margin.right) + ' ' + diameter)
            //     .attr('width', (diameter + margin.right))
            //     .attr('height', diameter)
            //     .attr('class', 'chart-svg');
            //------------
            // SVG 요소 생성
            var svg = d3.select(chartRef.current).append('svg')
            .attr('viewBox', '0 0 ' + (diameter + margin.right) + ' ' + diameter)
            .attr('width', 0) // 초기 너비를 0으로 설정
            .attr('height', diameter)
            .attr('class', 'chart-svg');

            // CSS를 사용하여 애니메이션 적용
            svg.transition() // 애니메이션 시작
            .duration(1000) // 애니메이션 지속 시간 (밀리초)
            .style('width', (diameter + margin.right) + 'px'); // CSS 속성 변경
            //---------------------------------


            var root = d3.hierarchy(json)
                .sum(function(d) { return d.value; })
                .sort(function(a, b) { return b.value - a.value; });
            
            bubble(root);
            
            // ------transform animation 추가-----------------
            //------원본---
            // var node = svg.selectAll('.node')
            //     .data(root.children)
            //     .enter()
            //     .append('g').attr('class', 'node')
            //     .attr('transform', function(d) { return 'translate(' + d.x + ' ' + d.y + ')'; })
            //     .append('g').attr('class', 'graph');
            //------------
            var node = svg.selectAll('.node')
                .data(root.children)
                .enter()
                .append('g').attr('class', 'node')
                .attr('transform', 'translate(0,0)')
                .append('g').attr('class', 'graph')
                .on('mouseover', function() { // 호버 이벤트 처리
                    d3.select(this).transition() // 호버 시 애니메이션 시작
                        .duration(200) // 애니메이션 지속 시간 (밀리초)
                        .attr('filter', 'saturate(2.5)'); // 호버 시 크기를 1.1배로 확대
                })
                .on('mouseout', function() { // 호버 해제 이벤트 처리
                    d3.select(this).transition() // 호버 해제 시 애니메이션 시작
                        .duration(200) // 애니메이션 지속 시간 (밀리초)
                        .attr('filter', 'saturate(1)'); // 호버 해제 시 원래 크기로 복원
                });
                
            node.transition()
                .duration(1000)
                .attr('transform', function(d) { return 'translate(' + d.x + ' ' + d.y + ')'; })
            //---------------------------------


            function getGradient(name) {
                // name에 따라 색상을 결정하는 로직 구현
                if (name === '식이섬유') {
                    return ['rgba(255,102,129,0.5)','rgba(255,102,129,0.8)'];
                } else if (name === 'DHA+EPA') {
                    return ['rgba(76, 192, 192,0.5)','rgba(76, 192, 192,0.8)'];
                } else if (name === '비타민A') {
                    return ['rgba(252, 233, 136,0.5)','rgba(252, 233, 136,0.8)'];
                } else if (name === '트립토판') {
                    return ['rgba(58,166,228, 0.5)','rgba(58,166,228, 0.8)'];
                } else if (name === '엽산') {
                    return ['rgba(253,248,134,0.5)','rgba(253,248,134,0.8)'];
                } else if (name === '비타민D') {
                    return ['rgba(199, 251, 135,0.5)','rgba(199, 251, 135,0.8)'];
                } else if (name === '마그네슘') {
                    return ['rgba(171,195,88,0.5)','rgba(171,195,88,0.8)'];
                } else if (name === '비타민B12') {
                    return ['rgba(255,158,64,0.5)','rgba(255,158,64,0.8)'];
                } else if (name === '비타민B6') {
                    return ['rgba(253, 179, 134,0.5)','rgba(253, 179, 134,0.8)'];
                }else if(name === '나트륨'){
                    return ['rgba(133, 234, 253,0.5)','rgba(133, 234, 253,0.8)'];
                }else if(name === '고열량'){
                    return ['rgba(248,166, 162, 0.5)', 'rgba(248,166, 162, 0.8)'];
                }else if(name === '단백질'){
                    return ['rgba(255,215,0,0.5)', 'rgba(255,215, 0, 0.8)'];
                }else if(name === '아연'){
                    return ['rgba(168,174, 190, 0.5)', 'rgba(168,174, 190, 0.8)'];
                }else if(name === '포화지방'){
                    return ['rgba(255,192, 203, 0.5)', 'rgba(255,192, 203, 0.8)'];
                }else if(name === '지방'){
                    return ['rgba(255,140, 0, 0.5)', 'rgba(255,140, 0, 0.8)'];
                }else if(name === '저열량'){
                    return ['rgba(235,218, 156, 0.5)', 'rgba(235,218, 156, 0.8)'];
                }else if(name === '고포드맵'){
                    return ['rgba(249,161, 183, 0.5)', 'rgba(249,161, 183, 0.8)'];
                }else if(name === '수분'){
                    return ['rgba(161, 249, 245, 0.5)', 'rgba(161,249, 245, 0.8)'];
                }else if(name === '콜레스테롤'){
                    return ['rgba(245, 161, 249, 0.5)', 'rgba(245, 161, 249, 0.8)'];
                }else if(name === '유당'){
                    return ['rgba(241, 249, 161, 0.5)', 'rgba(241, 249, 161, 0.8)'];
                }else{
                    return ['rgba(156, 133, 253,0.1)','rgba(156, 133, 253,0.3)'];
                }
                // 다른 영양소에 대한 색상 추가
            }

            // 그라데이션 정의
            json.children.forEach(function(child) {
                var gradient = svg.append("defs")
                    .append("linearGradient")
                    .attr("id", "gradient-" + child.name)
                    .attr("x1", "0%")
                    .attr("x2", "100%")
                    .attr("y1", "0%")
                    .attr("y2", "0%");

                gradient.append("stop")
                    .attr("offset", "0%")
                    .style("stop-color", getGradient(child.name)[0]); // 시작 색상

                gradient.append("stop")
                    .attr("offset", "100%")
                    .style("stop-color", getGradient(child.name)[1]); // 끝 색상
            });

            node.append("circle")
                .attr("r", function(d) { return d.r; })
                .style("fill", function(d) {
                return "url(#gradient-" + d.data.name + ")";
            });
            
            node.append("text")
                .attr("dy", ".3em")
                .style("text-anchor", "middle")
                .style("font-size", "0.8rem") 
                .text(function(d) { return d.data.name; })
                .style("width", "100%")
                .style("fill", "#222");

            setIsGraphGenerated(true); // 그래프가 생성됨을 표시
        }        
        }catch{}
    }, [uniqueNuCal, uniqueNuList,json_children, isGraphGenerated,targetDate, targetRangeDate, props.check]);

    return (
        <div ref={chartRef} className="chart bubblechart">
        </div>
    );
}

export default Bubble;
