import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
export const BtnBox = styled.div`
    text-align: center;
    .full{
        display: block;
        width: 100%;
        margin: 1rem 0;
        border: none;
        font-weight: 500;
    }
    .center{
        margin: 0 auto;
    }
    .primary{
        background-image: linear-gradient(to right, #3C3C3C 0%, #27322d  51%, #3C3C3C  100%); 
        // background: #3C3C43;
        transition: 0.5s;
        background-size: 200% auto;
        color: white;            
        box-shadow: 0 0 20px #eee;
    }
        .primary:hover{
            background-position: right center; /* change the direction of the change here */
        }
    .secondary{
        background: #13606c;
        border: none;
    }
    .third{
        background: #79797e;
        border:none;

    }
    .lightgray{
        background: #f4f4f4;
        color: #222;
        border: 1px solid #DBDBDB;
    }
    .border-no{
        border:none !important;
    }
    .grayborder{
        background: #fff;
        border: 1px solid #dbdbdb;
        color: #222;
        min-width: 160px;
        padding-top: 8px;
        padding-bottom: 8px;
        @media all and (max-width:1024px){
            width: 100%;
        }
    }
    .grayborder:hover{
        background: #dbdbdb;
        font-weight:800;
    }
    .pinegreen{
        background: #01716D;
    }
    .cornflowerblue{
        background: cornflowerblue;
    }
    .lightpink{
        background: #ffc4c4;
    }
    .lightsalmon{
        background: rgb(243, 130, 64);
    }
`         

//    background-image: linear-gradient(to right, #ff7e5f 0%, #feb47b  51%, #ff7e5f  100%);


const Btn = (props)=>{
    return(
        <BtnBox
            style={props.box}>
            <Button variant={props.variant}
                size={props.size}
                type={props.type}
                className={props.className}
                onClick={props.onClick}
                style={props.style}
            >{props.value}</Button>
        </BtnBox>
    )
}
export default Btn