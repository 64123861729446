import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    all_incongruity: [],
    all_allergy: [],
    all_affliction: [{id:1, affliction:'',affliction_detail:''}],
    food_category: ['채소', '과일', '콩/두부', '통곡물', '버섯', '해조류', '견과','수산/축산','유제품'],
    isLoading: false,
};

const BasicSlice = createSlice({
    name: 'basic',
    initialState,
    reducers:{
        allallergysave(state,action){
            state.all_allergy = action.payload.all_allergy;
        },
        allincongruitysave(state,action){
            state.all_incongruity = action.payload.all_incongruity;
        },
        allafflictionsave(state,action){
            state.all_affliction = action.payload.all_affliction;
        },
        setisLoading(state,action){
            state.isLoading = action.payload.isLoading;
        }
    }
})

export const basicActions = BasicSlice.actions;
export default BasicSlice.reducer;