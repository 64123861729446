import styled from "styled-components";

export const AboutSection = styled.section`
    width: 100%;
`

export const AboutLogoBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    @media all and (max-width:1024px){
        flex-direction: column-reverse;
    }
    .about_logo{
        width: 75%; 
        @media all and (max-width:1024px){
            width: 100%;
        }
    }
    img{
        width: 100%;
        max-width: 150px;
        max-height: 150px;
        object-fit: contain;  
        @media all and (max-width:1024px){
            padding-bottom: 16px;
            margin-bottom: 16px;
        }
    }

`
export const AboutNews = styled.div`
    width: 100%;
    display: flex;
    // flex-wrap: wrap;
    overflow: hidden;
`
export const NewsListWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
`
export const NewsList = styled.div`
    display: flex;
    width: 100%;
    border: 1px solid darkgray;
    border-radius: 4px;
    background: rgba(255,255,255,0.5);

    .img{
        width: 60%;
        height: 160px;
        @media all and (max-width:500px){
            height: 125px;
        }
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`
export const NewsListTitle = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    // padding: 24px 16px 16px;
    padding: 4px;
    @media all and (max-width:500px){
        padding: 0;
    }
    p{
        max-height: 128px; 
        line-height: 1.4;
        font-size: 14px;
        padding-top: 8px;
        font-weight: 300;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
`
export const GuidelineBox = styled.div`

`

export const NewsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid darkgray;
    width: calc(100% / 3 - 8px);
    margin: 4px;
    max-width: 380px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
    @media all and (max-width:1024px){
        width: calc(100% / 2 - 8px);
    }
    
    .news_title{
        padding: 16px 16px 8px ;
        text-align: left;
        @media all and (max-width:1024px){
            padding: 16px 8px 8px ;
        }
    }
    img{
        width: 100%;
        height: 160px;
        object-fit: cover;
    }
    p{
        padding: 0px 16px;
        margin-bottom: 16px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 300;
        font-size: 13px;
        @media all and (max-width:1024px){
            padding: 0px 8px;
        }
    }

`

export const AboutTitle = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 0;
    span{
        padding: 4px;
        margin-left: 16px;
        font-size: 13px;
        color: darkgray;
        text-decoration: 1px solid underline darkgray;
    }
`