import styled from "styled-components";
import {motion} from 'framer-motion';

export const AnalysisWrap = styled(motion.div)`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 32px;
    justify-content: space-between;
    @media all and (max-width:1024px){
        padding-top: 20px;
    }
    .full{
        width: 100%;
    }
    .gray_border{
        border: 1px solid #dbdbdb;
    }

`
export const AnalysisBox =styled(motion.div)`
    width: calc(50% - 0.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    .semibold{
        padding: 8px 0px;
    }
    @media all and (max-width:1024px){
        width: 100%;
    }
`
export const AnalysisContentBox = styled.div`
    width:100%; 
    height: 100%;
    background: white;
    // box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 5px;
    padding: 16px;
    position: relative;
    p{
        span{
            display: inline-block;
            font-size: 0.9rem;
            font-weight: 300;
            padding: 4px 0;
            margin-bottom: 16px;
            line-height: 1.2rem;
        }
    }
    .bold{
        text-align: left;
        @media all and (max-width:1024px){
            text-align: center;
        }
    }
    .flex{
        display: flex;
        align-items: center;
        @media all and (max-width:1024px){
            flex-direction: column;
        }
    }
    .bmi_title{
        width: 50%;
        max-width: 500px;
        padding-left: 16px;
        div{
            text-align: left;
        }
        @media all and (max-width:1024px){
            width: 100%;
            padding-bottom: 16px;
            padding-left: 0;
        }
    }
`