import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useState,useEffect } from "react";

ChartJS.register(ArcElement, Tooltip);

export const GaugeChart = (props) => {
    const [value, setValue] = useState(props.value);
    let getValue = 50 - value;

    const data = {
        labels: ['저체중','정상','과체중','비만'],
        datasets: [
        {
            label: 'BMI지수',
            data:[18.5, 23, 25, 40],
            // data: [value, getValue],
            backgroundColor: (context)=>{
                const chart = context.chart;
                const {ctx, chartArea} = chart;
                if(!chartArea){
                    return null
                }
                else if(context.dataIndex === 2 |context.dataIndex === 3){
                    return getRedGradient(chart);
                }
                else{
                    return getGradient(chart);
                }
                // [
                // 'rgba(54, 162, 235, 0.5)', // 하늘색
                // 'rgba(75, 192, 192, 0.5)', //연청색
                // 'rgba(255, 159, 64, 0.5)', //연주황
                // 'rgba(255, 99, 132, 0.5)', //연분홍
                // 'rgba(54, 162, 235, 0.2)', //하늘색
                // 'rgba(255, 206, 86, 0.2)', //연노랑

                // 'rgba(153, 102, 255, 0.2)', //연보라
            // ],
            },
            borderWidth: 0,
            cutout: "60%",
            circumference: 180,
            rotation: 270,
        },
      ],
    };

    function getGradient(chart){
        const {ctx, chartArea:{top, bottom, left, right}} = chart;
        const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);
        gradientSegment.addColorStop(0, 'rgba(54, 162, 235, 1)');
        gradientSegment.addColorStop(0.25, 'rgba(75, 192, 192, 1)');
        return gradientSegment;
    }
    function getRedGradient(chart){
        const {ctx, chartArea:{top, bottom, left, right}} = chart;
        const gradientSegment = ctx.createLinearGradient(left, 0, right, 0);
        gradientSegment.addColorStop(0.5, 'rgba(255, 159, 64, 1)');
        gradientSegment.addColorStop(1, 'rgba(255, 99, 132, 1)');
        return gradientSegment;
    }


    const chartText = {
        beforeDatasetDraw(chart) {
          const {
            ctx,
            chartArea: { left, right },
          } = chart;
    
          ctx.save();
          const xCoor = chart.getDatasetMeta(0).data[0].x;
          const yCoor = chart.getDatasetMeta(0).data[0].y;

          if (value <= 18.5){
            ctx.fillStyle = "blue";
          }else if(value <= 23){
            ctx.fillStyle = "green";
          }else if(value <= 25){
            ctx.fillStyle = "orangered";
          }else{
            ctx.fillStyle = "red";
          }
          
          ctx.textAlign = "center";
          ctx.fillText(`${value}`, xCoor, yCoor);
          ctx.font = "bold 24px sans-serif";
        },
    };

    return (
        <Pie data={data} plugins={[chartText]} />
    );
}

