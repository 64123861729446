import styled from "styled-components";

export const CircleContainer = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: 800;
    .circle-item {
        position: absolute;
        width: 105px;
        height: 105px;
        // background-color: #3498db;
        color: #fff;
        text-align: center;
        line-height: 50px;
        border-radius: 50%;
      }
      
      .circle-item:nth-child(1) { transform: rotate(240deg) translate(110px) rotate(-270deg); }
      .circle-item:nth-child(2) { transform: rotate(280deg) translate(110px) rotate(-270deg); }
      .circle-item:nth-child(3) { transform: rotate(320deg) translate(110px) rotate(90deg); }
      .circle-item:nth-child(4) { transform: rotate(360deg) translate(110px) rotate(90deg); }
      .circle-item:nth-child(5) { transform: rotate(40deg) translate(110px) rotate(90deg); }
      .circle-item:nth-child(6) { transform: rotate(80deg) translate(110px) rotate(90deg); }
      .circle-item:nth-child(7) { transform: rotate(120deg) translate(110px) rotate(90deg); }
      .circle-item:nth-child(8) { transform: rotate(160deg) translate(110px) rotate(90deg); }
      .circle-item:nth-child(9) { transform: rotate(200deg) translate(110px) rotate(-270deg); }
      .circle-item:nth-child(10) { transform: rotate(240deg) translate(110px) rotate(-270deg); }
      
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        opacity: 0.2;
        filter: grayscale(1.5);
    }
    // .circle-item:nth-child(1) img { transform: rotate(36deg)}
    // .circle-item:nth-child(2)  img { transform: rotate(72deg)}
    // .circle-item:nth-child(3)  img { transform: rotate(108deg)}
    // .circle-item:nth-child(4)  img { transform: rotate(144deg)}
    // .circle-item:nth-child(5)  img { transform: rotate(180deg)}
    // .circle-item:nth-child(6) img { transform: rotate(216deg)}
    // .circle-item:nth-child(7)  img { transform: rotate(242deg)}
    // .circle-item:nth-child(8)  img { transform: rotate(278deg)}
    // .circle-item:nth-child(9)  img { transform: rotate(314deg)}
    // .circle-item:nth-child(10)  img { transform: rotate(360deg)}
    .lack{
        opacity: 1;
        filter: brightness(1);
    }
`

