import styled from 'styled-components';
export const TitleBox = styled.div`
    color: inherit;
    text-align: center;    
    white-space: pre-line;
    line-height: 1.6;
    div{
        color: inherit;
    }
    .logo{
        font-size: 1.3rem;
        font-weight: bolder;
        cursor: pointer;
        @media all and (max-width:1024px){
            font-size: 1.1rem;
        }
    }
    .big_logo{
        font-size: 2rem;
        font-weight: bolder;
        cursor: pointer;
        @media all and (max-width:1024px){
            font-size: 1.8rem;
        }
    }
    .black{
        font-size: 1.8rem;
        font-weight: 900;
        @media all and (max-width:479px){
            font-size: 1.5rem;
        }
        cursor: pointer;
    }
    .bold{
        font-size: 1.5rem;
        font-weight: bolder;
        @media all and (max-width:479px){
            font-size: 1.3rem;
        }
    }
    .w_800{
        font-weight: bolder !important;
    }
    .w_900{
        font-weight: 900 !important;
    }
    .medium{
        font-size: 1.3rem;
        font-weight: bold;
        @media all and (max-width:479px){
            font-size: 1.15rem;
        }
    }
    .semibold{
        font-size: 1.2rem;
        font-weight: 500;
        @media all and (max-width:479px){
            font-size: 1rem;
        }
    }
    .regular{
        font-size: 1rem;
        font-weight: 500;
        @media all and (max-width:479px){
            font-size: 0.95rem;
        }
    }
    .light{
        font-size: 0.95rem;
        font-weight: 300;
        @media all and (max-width:479px){
            font-size: 0.9rem;
        }
    }
    .thin{
        font-size: 0.9rem;
        font-weight: 300;
        @media all and (max-width:479px){
            font-size: 0.85rem;
        }
    }

    .gray{
        color: #888888;
    }
    .serif{
        font-family: 'Noto Serif KR', serif;
    }
    .detail_center{
        width: 100%;
        text-align: center;
    }
    .text-left{
        text-align: left;
    }
    .white{
        color: white;
    }
    .pre-wrap{
        white-space: pre-wrap;
    }
    .text-justify{
        text-align: justify;
    }
`
const Title = (props)=>{
    return(
        <TitleBox
            style={props.box}>
            <div 
            style={props.style}
            onClick={props.onClick}
            className={props.className}>
               {props.value} 
            </div>
        </TitleBox>
    )
}
export default Title;