import { createSlice } from "@reduxjs/toolkit";

const currentDate = new Date();
const currentMonth = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;

const initialState = {
    analysisData: {
        [currentMonth]: [],
        // 나머지 월들도 필요에 따라 추가할 수 있습니다
    },
    targetdate: formattedDate,
    targetmonth: currentMonth,
    targetstartdate: formattedDate,
    targetenddate: formattedDate,
    check: 'today'
};
  
const AnalysisSlice = createSlice({
    name: "analysis",
    initialState,
    reducers: {
      analysisget(state, action){
        const { month, data } = action.payload;
        // 이미 해당 월에 데이터가 존재하는지 확인
        if (!state.analysisData[month] || state.analysisData[month].length === 0) {
          // 해당 월에 데이터가 없으면 새로 추가
          state.analysisData[month] = [data];
        }
      },
      settargetdate(state, action){
        state.targetdate = action.payload.targetdate;
      },
      settargetmonth(state,action){
        state.targetmonth = action.payload.targetmonth;
      },
      setcheck(state,action){
        state.check = action.payload.check;
      },
      settargetperioddate(state,action){
        state.targetstartdate = action.payload.targetstartdate;
        state.targetenddate = action.payload.targetenddate;
      }
    }
  });
  
  export const analysisActions = AnalysisSlice.actions;
  export default AnalysisSlice.reducer;