import styled from 'styled-components';
import Title from '../Title/Title';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion"


export const FooterWrap = styled.div`
    max-width: 1024px;
    margin: 0 auto;
    border-top: 1px solid #eee;
    background: white;
    position: fixed;
    top:0;
    left:0;
    background: #fffdfa;
    height: 100%;
    z-index: 100;
    border-right: 1px solid #ececec;
    @media all and (max-width:1024px){
        height: auto;
        width: 100%;
        position: fixed;
        top: auto;
        left: auto;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: none;
        border-top: 1px solid #ececec;
    }
    @media all and (max-width:500px){
        max-width: 600px;
    }  
`
export const FooterBox = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    @media all and (max-width:1024px){
        flex-direction: row;
    }
    span{
        padding: 12px 12px 4px;
        border-radius: 2px;
        cursor: pointer;
        color: inherit;
    }
    .thin{
        padding-bottom: 16px;
    }
    .check{
        // background: #FCECA9;
        // background: rgba(39, 50, 45, 1);
        background: white;
        color: #02B7B1;
        box-shadow: rgba(63, 174, 216, 0.25) 0px 4px 8px -2px, rgba(63, 174, 216, 0.8) 0px 0px 0px 1px;
        border-radius: 4px;
    }
    >div{
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        @media all and (max-width:1024px){
            width: 20%;
        }
    }
`

const Footer = (props)=>{
    const navigate = useNavigate();
    const userId = useSelector((state)=>state.auth.userId);

    const location = useLocation();
    const footerAnimate = {
        checkNav : {background: 'white', color:'#02B7B1',boxShadow:'rgba(1, 113, 109, 0.25) 0px 4px 8px -2px, rgb(1 113 109 / 80%) 0px 0px 0px 1px', borderRadius: '4px'}
    }

    return(
       <FooterWrap style={props.style}>
            <FooterBox>
                <motion.div
                    variants={footerAnimate}
                    animate={location.pathname.startsWith('/record')?"checkNav":null}
                    transition={{duration:0.5, type:'tween'}}
                    onClick={()=>{
                        navigate("/record")}}>
                    <motion.span className="material-symbols-outlined">edit_calendar</motion.span>
                    <Title className="thin w_800" value="기록" />
                </motion.div>
                <motion.div 
                    variants={footerAnimate}
                    animate={location.pathname === '/analysis' ? "checkNav" : null}
                    transition={{duration:0.5, type:'tween'}}
                    onClick={()=>{
                        navigate("/analysis")}}>
                    <motion.span className="material-symbols-outlined">insert_chart</motion.span>
                    <Title className="thin w_800" value="분석" />      
                </motion.div>
                <motion.div
                    variants={footerAnimate}
                    animate={location.pathname === '/' || location.pathname.startsWith('/affdet/')? "checkNav" : null}
                    transition={{duration:0.5, type:'tween'}}
                    onClick={()=>{
                        navigate("/")}}>
                    <motion.span className="material-symbols-outlined">home</motion.span>
                    <Title className="thin w_800" value="마켓" />     
                </motion.div>
                <motion.div                    
                    variants={footerAnimate}
                    animate={location.pathname === '/about' ? "checkNav" : null}
                    transition={{duration:0.5, type:'tween'}}
                    onClick={()=>{
                        navigate("/about");}}>
                    <motion.span className="material-symbols-outlined">book_2</motion.span>
                    <Title className="thin w_800" value="자료" />     
                </motion.div>
                <motion.div
                    variants={footerAnimate}
                    animate={location.pathname.startsWith('/mypage/') ? "checkNav" : null}
                    transition={{duration:0.5, type:'tween'}}
                    onClick={()=>{
                        navigate("/mypage/"+userId)}}>
                    <motion.span className="material-symbols-outlined">person</motion.span>
                    <Title className="thin w_800" value="나" />    
                </motion.div>
            </FooterBox>
        </FooterWrap>
    )
}
export default Footer;