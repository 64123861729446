import React, { useEffect, useRef, useState } from "react";
import * as d3 from 'd3';
import './Bubble.css';
import { useSelector } from "react-redux";

const AfflictionBubble = (props) => {
    const chartRef = useRef(null);
    const [isGraphGenerated, setIsGraphGenerated] = useState(false); // 상태 추가
    const [json_children, setJsonChildren] = useState([{name:'test', value: 0, color:'darkgreen'}])

    useEffect(()=>{
        // 이전에 생성된 SVG 요소 제거
        d3.select(chartRef.current).selectAll('svg').remove();
        setIsGraphGenerated(false);
    },[json_children]);

    useEffect(()=>{
        setJsonChildren(props.json_children)
    },[props.json_children])

    useEffect(() => {
        try{
        if (json_children[0].value !== 0 && json_children[0].name !== 'test' && !isGraphGenerated) {
            var json = {
                'children': json_children
            }
                // 'children': [
                //     {'name': '식이섬유', 'value': 10, color:'orange'},
                //     {'name': 'Oranges', 'value': 44, color:'red'},
                //     {'name': 'Kiwis', 'value': 65, color:'blue'},
                //     {'name': 'Bananas', 'value': 39, color:'orange'},
                //     {'name': 'Pears', 'value': 10, color:'green'},
                //     {'name': 'Satsumas', 'value': 25, color:'orange'},
                //     {'name': 'Pineapples', 'value': 30, color:'orange'}
                // ]

            var diameter = 250;
            
            var colorScale = d3.scaleLinear()
                .domain([0, d3.max(json.children, function(d) {
                    return d.value;
                })])
                .range(["rgb(46, 73, 123)", "rgb(71, 187, 94)"]);
            
            var bubble = d3.pack()
                .size([diameter, diameter])
                .padding(3);
            
            var margin = {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }

            // ------width animation 추가-----------------
            //------원본---
            // var svg = d3.select(chartRef.current).append('svg')
            //     .attr('viewBox', '0 0 ' + (diameter + margin.right) + ' ' + diameter)
            //     .attr('width', (diameter + margin.right))
            //     .attr('height', diameter)
            //     .attr('class', 'chart-svg');
            //------------
            // SVG 요소 생성
            var svg = d3.select(chartRef.current).append('svg')
            .attr('viewBox', '0 0 ' + (diameter + margin.right) + ' ' + diameter)
            .attr('width', 0) // 초기 너비를 0으로 설정
            .attr('height', diameter)
            .attr('class', 'chart-svg');

            // CSS를 사용하여 애니메이션 적용
            svg.transition() // 애니메이션 시작
            .duration(1000) // 애니메이션 지속 시간 (밀리초)
            .style('width', (diameter + margin.right) + 'px'); // CSS 속성 변경
            //---------------------------------


            var root = d3.hierarchy(json)
                .sum(function(d) { return d.value; })
                .sort(function(a, b) { return b.value - a.value; });
            
            bubble(root);
            
            // ------transform animation 추가-----------------
            //------원본---
            // var node = svg.selectAll('.node')
            //     .data(root.children)
            //     .enter()
            //     .append('g').attr('class', 'node')
            //     .attr('transform', function(d) { return 'translate(' + d.x + ' ' + d.y + ')'; })
            //     .append('g').attr('class', 'graph');
            //------------
            var node = svg.selectAll('.node')
                .data(root.children)
                .enter()
                .append('g').attr('class', 'node')
                .attr('transform', 'translate(0,0)')
                .append('g').attr('class', 'graph')
                .on('mouseover', function() { // 호버 이벤트 처리
                    d3.select(this).transition() // 호버 시 애니메이션 시작
                        .duration(200) // 애니메이션 지속 시간 (밀리초)
                        .attr('filter', 'saturate(2.5)'); // 호버 시 크기를 1.1배로 확대
                })
                .on('mouseout', function() { // 호버 해제 이벤트 처리
                    d3.select(this).transition() // 호버 해제 시 애니메이션 시작
                        .duration(200) // 애니메이션 지속 시간 (밀리초)
                        .attr('filter', 'saturate(1)'); // 호버 해제 시 원래 크기로 복원
                });
                
            node.transition()
                .duration(1000)
                .attr('transform', function(d) { return 'translate(' + d.x + ' ' + d.y + ')'; })
            //---------------------------------


            function getGradient(name) {
                // name에 따라 색상을 결정하는 로직 구현
                if (name === '식이섬유'){
                    return ['rgba(255,102,129,0.5)','rgba(255,102,129,0.8)'];
                }else if (name === 'DHA+EPA'){
                    return ['rgba(76, 192, 192,0.5)','rgba(76, 192, 192,0.8)'];
                }else if (name === '비타민A'){
                    return ['rgba(252, 233, 136,0.5)','rgba(252, 233, 136,0.8)'];
                }else if (name === '트립토판'){
                    return ['rgba(58,166,228, 0.5)','rgba(58,166,228, 0.8)'];
                }else if(name === '엽산'){
                    return ['rgba(253,248,134,0.5)','rgba(253,248,134,0.8)'];
                }else if(name === '비타민D'){
                    return ['rgba(199, 251, 135,0.5)','rgba(199, 251, 135,0.8)'];
                }else if(name === '마그네슘'){
                    return ['rgba(171,195,88,0.5)','rgba(171,195,88,0.8)'];
                }else if(name === '비타민B12'){
                    return ['rgba(255,158,64,0.5)','rgba(255,158,64,0.8)'];
                }else if(name === '비타민B6'){
                    return ['rgba(253, 179, 134,0.5)','rgba(253, 179, 134,0.8)'];
                }else if(name === '나트륨'){
                    return ['rgba(133, 234, 253,0.5)','rgba(133, 234, 253,0.8)'];
                }else if(name === '고열량'){
                    return ['rgba(248,166, 162, 0.5)', 'rgba(248,166, 162, 0.8)'];
                }else if(name === '단백질'){
                    return ['rgba(255,215,0,0.5)', 'rgba(255,215, 0, 0.8)'];
                }else if(name === '아연'){
                    return ['rgba(168,174, 190, 0.5)', 'rgba(168,174, 190, 0.8)'];
                }else if(name === '포화지방'){
                    return ['rgba(255,192, 203, 0.5)', 'rgba(255,192, 203, 0.8)'];
                }else if(name === '지방'){
                    return ['rgba(255,140, 0, 0.5)', 'rgba(255,140, 0, 0.8)'];
                }else if(name === '저열량'){
                    return ['rgba(235,218, 156, 0.5)', 'rgba(235,218, 156, 0.8)'];
                }else if(name === '고포드맵'){
                    return ['rgba(249,161, 183, 0.5)', 'rgba(249,161, 183, 0.8)'];
                }else if(name === '수분'){
                    return ['rgba(161, 249, 245, 0.5)', 'rgba(161,249, 245, 0.8)'];
                }else if(name === '콜레스테롤'){
                    return ['rgba(245, 161, 249, 0.5)', 'rgba(245, 161, 249, 0.8)'];
                }else if(name === '유당'){
                    return ['rgba(241, 249, 161, 0.5)', 'rgba(241, 249, 161, 0.8)'];
                }else{
                    return ['rgba(156, 133, 253,0.1)', 'rgba(156, 133, 253,0.3)'];
                }
                // 다른 영양소에 대한 색상 추가
            }

            // 그라데이션 정의
            json.children.forEach(function(child) {
                var gradient = svg.append("defs")
                    .append("linearGradient")
                    .attr("id", "gradient-" + child.name)
                    .attr("x1", "0%")
                    .attr("x2", "100%")
                    .attr("y1", "0%")
                    .attr("y2", "0%");

                gradient.append("stop")
                    .attr("offset", "0%")
                    .style("stop-color", getGradient(child.name)[0]); // 시작 색상

                gradient.append("stop")
                    .attr("offset", "100%")
                    .style("stop-color", getGradient(child.name)[1]); // 끝 색상
            });

            node.append("circle")
                .attr("r", function(d) { return d.r; })
                .style("fill", function(d) {
                return "url(#gradient-" + d.data.name + ")";
            });
            
            node.append("text")
                .attr("dy", ".3em")
                .style("text-anchor", "middle")
                .style("font-size", "0.7rem")
                .style("font-weight", '500')
                .text(function(d) { return d.data.name; })
                .style("width", "100%")
                .style("fill", "#222");

            setIsGraphGenerated(true); // 그래프가 생성됨을 표시
        }        
        }catch{}
    }, [json_children, isGraphGenerated]);

    return (
        <div ref={chartRef} className="chart bubblechart">
        </div>
    );
}

export default AfflictionBubble;
