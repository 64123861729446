import React, { useState, forwardRef, useEffect} from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import Title from '../../atom/Title/Title';
import { useDispatch, useSelector } from 'react-redux';
import { analysisActions } from '../../../store/analysis-slice';
import Btn from '../../atom/Btn/Btn';
import styled from 'styled-components';

// 한국어 로케일을 등록합니다.
registerLocale('ko', ko);

const DatePickerWrap = styled.div`
  display: flex;
  button{
    padding: 8px;
    border:none;
    border-radius: 4px;
    background:transparent;
  }
  .check{
    button{
      background:rgb(39, 50, 45);
      color: white;
    }
  }
`
const AnalysisDateBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button:hover, button:focus{
    span{
      color: white;}
    background: #dbdbdb;
    color: #222;
  }

`
const AnalysisDatePick = styled.div`
  .check{
    background: rgb(39, 50, 45);
    color: white;
  }
`

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <button className="example-custom-input" onClick={onClick} ref={ref}>
      <Title className="bold" value={value} />
      {/* {value} */}
  </button>
));


const SingleDatePicker = (props) => {
  const dispatch = useDispatch();
  const targetDate = useSelector((state) => state.analysis.targetdate);
  const today = new Date(targetDate);

  const [selectedDate, setSelectedDate] = useState(today);

  useEffect(()=>{
    let formattedDate = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
    dispatch(
      analysisActions.settargetdate({
        targetdate : formattedDate
      })
    );
  },[selectedDate])
  useEffect(()=>{
    if(props.check == 'today'){
      setSelectedDate(new Date());
    }
  },[props.check])

  return (
      <DatePickerWrap>
        <div className={props.check==='day'?'check':null}>
          {/* <label>날짜 선택 : </label> */}
          <DatePicker 
              closeOnScroll={true}
              selected={selectedDate} 
              onChange={(date) => {setSelectedDate(date)}}
              selectsStart={false} 
              selectsEnd={false} 
              dateFormat="yyyy-MM-dd"
              locale="ko" // 한국어 로케일 적용
              customInput={<ExampleCustomInput />} 
          />
        </div>
      </DatePickerWrap>
  );
};


const DateRangePicker = (props) => {
    const dispatch = useDispatch();
    const targetStartDate = useSelector((state) => state.analysis.targetstartdate);
    const targetEndDate = useSelector((state) => state.analysis.targetenddate);

    const [selectedStartDate, setSelectedStartDate] = useState(new Date(targetStartDate));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date(targetEndDate));

    useEffect(()=>{
      let formattedStartDate = `${selectedStartDate.getFullYear()}-${String(selectedStartDate.getMonth() + 1).padStart(2, '0')}-${String(selectedStartDate.getDate()).padStart(2, '0')}`;
      let formattedEndDate = `${selectedEndDate.getFullYear()}-${String(selectedEndDate.getMonth() + 1).padStart(2, '0')}-${String(selectedEndDate.getDate()).padStart(2, '0')}`;
      dispatch(
        analysisActions.settargetperioddate({
          targetstartdate : formattedStartDate,
          targetenddate : formattedEndDate
        })
      );
    },[selectedStartDate, selectedEndDate])
    
    return (
      //DateRangePicker
      <div style={{display:'flex', alignItems:'center'}}>
        <DatePickerWrap>
          <div>
          <DatePicker 
              closeOnScroll={true}
              selected={selectedStartDate} onChange={(date) => setSelectedStartDate(date)} selectsStart 
              startDate={selectedStartDate} 
              endDate={selectedEndDate} 
              locale="ko" // 한국어 로케일 적용
              dateFormat="yyyy-MM-dd"
              customInput={<ExampleCustomInput />}  />
          </div>
        </DatePickerWrap>
        <Title className="bold" value={'~'} />
        <DatePickerWrap>
          <div>
          <DatePicker 
              closeOnScroll={true}
              selected={selectedEndDate} onChange={(date) => setSelectedEndDate(date)} selectsEnd 
              startDate={selectedStartDate} 
              endDate={selectedEndDate} 
              locale="ko" // 한국어 로케일 적용
              dateFormat="yyyy-MM-dd"
              minDate={selectedStartDate}
              customInput={<ExampleCustomInput />} />
          </div>
        </DatePickerWrap>
      </div>
    );
  };
  

const OffCanvasExample = ({ name, ...props }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const check = useSelector((state) => state.analysis.check);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const selectedDate = useSelector((state) => state.analysis.targetDate);
    const selectedStartDate = useSelector((state) => state.analysis.targetstartdate);
    const selectedEndDate = useSelector((state) => state.analysis.targetenddate);
  
    return (
      <>
        <AnalysisDateBtn className='mt-3'>
            {check ==='today' | check ==='day'?
              <SingleDatePicker check={check} />
            : <DateRangePicker check={check}  />}
            <AnalysisDatePick style={{display: 'flex'}}>
              <Btn onClick={()=>dispatch(analysisActions.setcheck({check:'today'}))} variant="primary" size="lg" type="submit" className={`lightgray ${check === 'today'?'check':null} ml-1`} value="오늘" />
              <Btn onClick={()=>dispatch(analysisActions.setcheck({check:'period'}))} variant="primary" size="lg" type="submit" className={`lightgray ${check === 'period'?'check':null} ml-1`} value="기간설정" />
            </AnalysisDatePick>
  
        </AnalysisDateBtn>
      </>
    );
  }
  export default OffCanvasExample;