import Router from './router';
import { createGlobalStyle } from 'styled-components';
import './index.css';
import InitialSetup from './InitialSetup';
import { useEffect } from 'react';

const GlobalStyle = createGlobalStyle`
    /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
    */

    /* Bold */
    @font-face {
        font-family: 'SeoulNamsan'; /* 웹 폰트의 이름 지정 */
        src: url('./components/lib/fonts/SeoulNamsanBold.woff2') format('font-woff2'); /* 웹 폰트 파일의 경로 및 형식 지정 */
        font-weight: bold; /* 폰트의 두께 지정 */
        font-style: normal; /* 폰트의 스타일 지정 */
    }

    /* ExtraBold */
    @font-face {
        font-family: 'SeoulNamsan'; /* 웹 폰트의 이름 지정 */
        src: url('./components/lib/fonts/SeoulNamsanExtraBold.woff2') format('font-woff2'); /* 웹 폰트 파일의 경로 및 형식 지정 */
        font-weight: bolder; /* 폰트의 두께 지정 */
        font-style: normal; /* 폰트의 스타일 지정 */
    }
    /* Medium */
    @font-face {
    font-family: 'SeoulNamsan'; /* 웹 폰트의 이름 지정 */
    src: url('./components/lib/fonts/SeoulNamsanMedium.woff2') format('font-woff2'); /* 웹 폰트 파일의 경로 및 형식 지정 */
    font-weight: 500; /* 폰트의 두께 지정 */
    font-style: normal; /* 폰트의 스타일 지정 */
    }

    /* Light */
    @font-face {
    font-family: 'SeoulNamsan'; /* 웹 폰트의 이름 지정 */
    src: url('./components/lib/fonts/SeoulNamsanLight.woff2') format('font-woff2'); /* 웹 폰트 파일의 경로 및 형식 지정 */
    font-weight: 300; /* 폰트의 두께 지정 */
    font-style: normal; /* 폰트의 스타일 지정 */
    }

*{
    box-sizing: border-box;
    font-family: 'SeoulNamsan','Arial', sans-serif;
}
html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        text-decoration: none;
        // color: #222;
        color: #3C3C43;
        // color: #26463a;
        
    }
    /* HTML5 display-role reset for older browsers */
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
        display: block;
    }

    body {
        font-family: 'SeoulNamsan','Arial', sans-serif;
        color: #222;
        &::-webkit-scrollbar{
            display: none; /* Chrome, Safari, Opera*/
        }
    }
    ol,
    ul {
        list-style: none;
    }
    blockquote,
    q {
        quotes: none;
    }
    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
`;


function App() {
    const initialized = localStorage.getItem('initialized');
    // useEffect를 사용하여 렌더링 이후에 localStorage를 설정합니다.
    useEffect(() => {
        // 로컬 스토리지에 값이 없는 경우에만 초기화합니다.
        if (!initialized || initialized === 'false') {
            localStorage.clear();
            localStorage.setItem('initialized', 'true');
        }
    }, [initialized]);



    
    return (
        <>
            <GlobalStyle />
            <InitialSetup />
            <Router />
        </>
    );
}

export default App;
