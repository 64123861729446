import Wrapper, { BodyWrapper } from '../../atom/Wrapper/Wrapper';
import Header,{ LoHeader } from '../../atom/Header/Header';
import Title from '../../atom/Title/Title';
import Footer from "../../atom/Footer/Footer";
import { useEffect, useState } from 'react';
import { useNavigate} from "react-router-dom"
import RecordCalendar from './RecordCalendar';
import { DayRecordBox, RecordFlex } from './Record.styled';
import {motion} from 'framer-motion';
import { useSelector } from 'react-redux';

const Record = () => {
    const navigate = useNavigate();
    const recordData = useSelector((state) => state.record.monthlyData);

    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더해줍니다.
    // 월이 한 자리수인 경우 앞에 0을 추가하여 두 자리로 만듭니다.
    const formattedMonth = month < 10 ? `0${month}` : month;
    const [currentMonth, setCurrentMonth] = useState(`${year}-${formattedMonth}`);

    useEffect(()=>{
        const keys = Object.keys(recordData);
        keys.forEach(key => {
          if(key === currentMonth){
            const array = recordData[key];
            // 배열의 길이가 0보다 큰 경우에만 출력
            if (array.length > 0) {
              const dateArray = array[0].map(date => date.record_date);
            };
          }else{
            
          }
        })
      },[recordData, currentMonth]);

    return(
        <>
        <BodyWrapper bg="recordbg" className="blurbg">
            <Header value={
                <LoHeader style={{justifyContent:'space-between'}}>
                    <Title className="logo" value="구매 기록" />
                    {/* <BasketNav>
                        <div onClick={()=>setCalendarList(0)} style={calendarlist===0?{background:'#27322d',color:'white', fontWeight: 800}:null}>
                            <span style={{color:'inherit'}} class="material-symbols-outlined">calendar_view_month</span>
                        </div>
                        <div onClick={()=>setCalendarList(1)} style={calendarlist===1?{background:'#27322d',color:'white', fontWeight: 800}:null}>
                        <span style={{color:'inherit'}} class="material-symbols-outlined">format_list_bulleted</span>
                        </div>
                    </BasketNav>                   */}
                </LoHeader>} />
            <Wrapper>
            <RecordFlex>
            <RecordCalendar setCurrentMonth={setCurrentMonth} />
                <DayRecordBox className="mb-5">
                    <motion.ul class="shopday"
                        variants={{
                            visible: {transition:{staggerChildren:0.08}}
                        }} 
                        initial="hidden" animate="visible">
                        {recordData[currentMonth] && recordData[currentMonth][0]?.map((item,index) => {
                            return(
                                <motion.li
                                    onClick={()=>navigate('/record/'+item.record_date)}
                                    variants={{
                                        hidden: {opacity:0, scale:0.5},
                                        visible: {opacity:1, scale: 0.99}
                                    }}
                                    key={index}>
                                    <p>
                                        {item.foods?.map((food,index) => (
                                            <Title key={index} className="regular text-left" value={food.food_name} />
                                        ))}
                                    </p>
                                    <Title className="light text-left" value={item.record_date} />
                                </motion.li>
                            )
                        })}
                    </motion.ul>
                </DayRecordBox>    
                </RecordFlex>
            </Wrapper>   
        </BodyWrapper>
        <Footer />
        </>
    )
}
export default Record;