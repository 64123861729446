import React, { useEffect, useState} from 'react';

import Header,{ LoHeader } from "../../../atom/Header/Header";
import Wrapper, { BodyWrapper } from "../../../atom/Wrapper/Wrapper";
import Title from "../../../atom/Title/Title";
import styled from "styled-components";
import Btn from "../../../atom/Btn/Btn";
import {useNavigate, useParams} from 'react-router-dom';
import NicePaySubscription from './NicePaySubscription';


const SubscriptionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

`
const SubscriptionBox = styled.div`
    padding: 1rem;
    margin: 10px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    width: 100%;
    max-width: 500px;
`


const Pay = (props) => {
    const params = useParams();
    const navigate = useNavigate();
      
    // const handlePremiumPayment = () => {
    //     window.AUTHNICE.requestPay({
    //         clientId: 'R2_bbd894b490f6492a8fbbf6ad6841c3b4',
    //         method: 'card',
    //         orderId: 'a8965034-763e-4c39-8c91-37b0926a737',
    //         amount: 25000,
    //         goodsName: '뉴지엄랩-3개월 이용권',
    //         returnUrl: 'http://nuseum.pe.kr/serverAuth',
    //         fnError: function (result) {
    //             alert('' + result.errorMsg);
    //         }
    //     });
    // };


    return(
        <BodyWrapper>
            <Header value={<LoHeader style={{justifyContent:'space-between'}}><Title className="medium" value="구독/결제" /></LoHeader>} />
            <Wrapper>
                {params.pack=='basic'?
                <div>
                    <Title className="medium mt-4" value={`뉴지엄 마켓\n개인맞춤영양 장바구니 플랫폼 정기구독`} />
                    <SubscriptionWrapper className="mt-3">
                            <SubscriptionBox>
                                <Title className="semibold" value='9,900원 / 월' />
                                <Title className="regular" value='카드 정보 입력' />
                                <NicePaySubscription choice={'basic'} />
                            </SubscriptionBox>
                    </SubscriptionWrapper>
                    
                </div>
                :
                <div>
                    <Title className="medium mt-4" value={`뉴지엄 마켓\n개인맞춤영양 장바구니 플랫폼 이용권`} />
                    <SubscriptionWrapper className="mt-3">
                            <SubscriptionBox>
                                <Title className="semibold" value='25,000원 / 3개월' />
                                <Title className="regular" value='16% 할인' />
                                <Title className="regular" value='카드 정보 입력' />
                                <NicePaySubscription choice={'premium'} />
                                {/* <Btn onClick={handlePremiumPayment} variant="primary" size="lg" type="submit" className="full lightsalmon mt-4 mb-3" value="결제하기" /> */}
                            </SubscriptionBox>
                    </SubscriptionWrapper>
                </div>
                }
                <Btn onClick={()=>navigate('/subscription')}
                        style={{border:'none'}}
                        variant="secondary" size="sm" className="grayborder" value="다시 선택하기" />
            </Wrapper>
        </BodyWrapper>
    )
}
export default Pay;