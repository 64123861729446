import styled from 'styled-components';

export const MypageTitle = styled.div`
    padding-top: 16px;
    cursor: pointer;
    button{
        background: #F2EFF4;
    }
    .accordion-item:first-of-type .accordion-button{
        background: #F2EFF4;
    }
    .accordion-button:focus{
        box-shadow: none;
    }
    div{
        background: #F2EFF4;
        border:none;
    }
`
export const MypageDetail = styled.div`
    padding: 0 16px 16px;
    div{
        padding: 8px;
        cursor: pointer;
        &:hover{
            color: darkgreen;
            font-weight: 600;
        }
    }
`
export const CardBox = styled.div`
    width: 100%;
    display: flex;
    padding: 32px;
    @media all and (max-width:1024px){
        padding: 8px;
        flex-wrap: wrap;
        // flex-direction: column;
        justify-content: space-between;
    }
    div{
        line-height: 1.3;
    }
    .regular{
        padding: 8px 0 4px 0;
    }
    .and{
        display: flex;
        flex-wrap: wrap;
    }
    .edit{
        position: absolute;
        right:0;
        top:0;
        padding: 10px;
        color:rgb(0 0 0 / 25%);
        cursor: pointer; 
    }
`
export const CardBoxTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 32px 32px 0;
    min-width: 300px;
    text-align: center;
    margin-right: 32px;
    border-right: 2px dotted #dbdbdb;
    p{padding: 8px 0;}
    @media all and (max-width:1024px){
        padding: 8px 0 16px;
        margin-bottom: 16px;
        flex-direction: row;
        width: 100%;
        min-width: auto;
        border-right: none;
        border-bottom: 2px dotted #dbdbdb;
        margin-bottom: 32px;
        margin-right: 0px;
        text-align: left;
    }
`

export const CardImg = styled.img`
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 100%;
    border: 1px solid #27322d;
    margin-bottom: 16px;
    @media all and (max-width:1024px){
        width: 80px;
        height: 80px;
        margin-right: 32px;
        margin-left: 0px;
        margin-bottom: 0px;
    }
`
export const CardBoxText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 32px;
    padding-left: 32px;
    min-width: 60%;
    @media all and (max-width:1024px){
        margin-left: 0px;
        padding-left: 0px;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    p{
        width: auto;
        min-width: 33%;
        padding: 16px 0 8px;
        padding-right: 16px;
        @media all and (max-width:1024px){
            width: auto;
            padding-right: 32px;
            min-width: 50%;
        }
    }
    .regular{
        padding-right: 8px;
    }
    div{
        text-align: left;
    }
`

export const DetailBox = styled.div`
    border-radius: 8px;
    border: 1px solid #eee;
    padding: 16px;
    

`