import styled from 'styled-components';

export const HomeCenterWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const HomeBox = styled.div`
    width: 100%;
    position: relative;

`

export const HomeImgSection = styled.section`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .wrap{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background: #27322d;
        img{
            max-width: 500px;
            margin: 0 12px;
        }
    }
    .other{
        width: 100%;
    }
    img{
        width: 100%;
        max-width: 700px;
        object-fit:cover;
    }
`