import ThemeProvider from 'react-bootstrap/ThemeProvider';
import Header,{ LoHeader } from '../../../../atom/Header/Header';
import Title from '../../../../atom/Title/Title';
import Wrapper, { FlexBox } from '../../../../atom/Wrapper/Wrapper';
import Footer from '../../../../atom/Footer/Footer';
import {DetailBox} from '../Mypage.styled'; 
import { useNavigate } from 'react-router-dom';

const Inquiry = () => {
    const navigate = useNavigate();
    return(
      <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs">
        <Header value={<LoHeader style={{justifyContent:'space-between'}}><Title className="medium dodum" value="문의" /></LoHeader>} />

        <Wrapper box={{position: 'relative'}}>
          <span onClick={()=>navigate(-1)} class="material-symbols-outlined" 
                              style={{cursor:'pointer', position: 'absolute', left:0, padding: '16px 16px 16px 8px'}}>arrow_back</span>
          <FlexBox className='column_center mt-4 mb-4 '>
            <Title className="medium mb-2" value="카카오 채널 @뉴지엄" />
            <a id="chat-channel-button" href="javascript:chatChannel()">
              <img src="https://developers.kakao.com/assets/img/about/logos/channel/consult_small_yellow_pc_2X.png"
                style={{maxWidth:'120px'}}
                alt="카카오톡 채널 채팅하기 버튼" />
            </a>
            <DetailBox className='mt-4'>
              <Title className="regular mt-2 text-left" value={`안녕하세요.${'\n'}
                개인에게 맞추어진 영양정보 제공, 헬스케어 커머스 플랫폼 뉴지엄랩입니다.${'\n'}
                문의하실 사항이 있다면 카카오톡 채널(뉴지엄)으로 언제든지 연락바랍니다.🥦${'\n'}
                문의가 많을 시 답변이 지연될 수 있습니다. 문의사항을 남겨주시면 확인 후 답변드리도록 하겠습니다.${'\n'}
                감사합니다 😊`} />
            </DetailBox>
          </FlexBox>
        </Wrapper>
        <Footer />
      </ThemeProvider>
    )
};
export default Inquiry;  
