import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlexBox } from "../../../atom/Wrapper/Wrapper";
import { ItemBox,ProductWrap,FoodNavWrap, FoodNavTitle,ProductScroll} from "./Basket.styled";
import Title from '../../../atom/Title/Title';
import vegImg from '../../../lib/img/market_food/veg.png';
import {motion} from 'framer-motion';
import {recordActions} from '../../../../store/record-slice';


const BasketMarket = (props) => {
    const dispatch = useDispatch();
    const main_user_id = useSelector((state) => state.user.main_user_id);
    const main_user_recommend_food = useSelector((state) => state.user.user_recommend_food);
    const main_user_recommend_product = useSelector((state) => state.user.user_recommend_product);
    const food_category_list = useSelector((state) => state.basic.food_category);
    
    const recordFoodList = useSelector((state) => (state.record.recordFoodList));

    const [activeSection, setActiveSection] = useState(null);
    const sectionRefs = useRef([]);
  
    // useEffect(() => {
    //   const handleIntersection = (entries) => {
    //     entries.forEach((entry) => {
    //       if (entry.isIntersecting) {
    //         setActiveSection(entry.target.id);
    //       }
    //     });
    //   };
  
    //   // IntersectionObserver 초기화
    //   const observer = new IntersectionObserver(handleIntersection, {
    //     root: null, // 뷰포트를 기준으로 교차 영역을 감지
    //     rootMargin: '0px',
    //     threshold: 0, // 0.5 이상이면 교차하는 것으로 간주
    //   });
  
    //   // 각 섹션 요소를 감시 대상으로 추가
    //   sectionRefs.current.forEach((sectionRef) => {
    //     observer.observe(sectionRef);
    //   });
  
    //   // IntersectionObserver 해제 함수
    //   return () => {
    //     observer.disconnect();
    //   };
    // }, []);

    const scrollToSection = (sectionId) => {
        const targetSection = document.getElementById(sectionId);
        if (targetSection) {
        //   targetSection.scrollIntoView({ 
        //     behavior: 'smooth',
        //     block: 'start',
        //     inline: 'nearest',
        //     });
            const yOffset = targetSection.getBoundingClientRect().top - 95;
            window.scroll({
            top: window.scrollY + yOffset,
            behavior: 'smooth',
            });
            
        }
      };

    const handleRecordFoodlist = (item_id) =>{
        dispatch(
            recordActions.recordFoodList({
                recordFoodList: [...recordFoodList, item_id]
            })
        )
    };
    const handleRemoveFoodlist = (item_id) => {
        dispatch(
            recordActions.recordFoodList({
                recordFoodList: recordFoodList.filter(item => item !== item_id)
            })
        );
    };

    return(
        <>
        <FoodNavWrap>
        <FoodNavTitle>
            {food_category_list?.map((category, index) => (
                <>
                {/*  className={activeSection === category? 'focus':null} 포커싱 클래스 추가 */}
                <span key={index} onClick={()=> scrollToSection(category)}>{category}</span>
                </>
            ))}
        </FoodNavTitle>
        </FoodNavWrap>
        <FlexBox className="column" style={{position: 'relative'}}>
            <ItemBox>
                    {food_category_list?.map((category, index) => (
                        <motion.ul>
                        {main_user_recommend_food?.map((item, index2) => (
                            <>
                            {item.food_category == index + 1?
                                <motion.li key={index2} ref={(el) => (sectionRefs.current[index] = el)} id={category}>
                                <Title className="semibold text-left" value={item.food_name} />
                                {/* <Title className="light ml-1" value="영양성분" /> */}
                                <ProductScroll>
                                    <ProductWrap
                                        variants={{visible:{transition:{staggerChildren:0.1}}}}
                                        initial="hidden" animate="visible">
                                    {main_user_recommend_product?.map((product, index3) => {
                                        let cnt = 0 
                                        if(cnt == 0 &&product.rec_product_category == item.food_category && product.food_names.includes(item.food_name)){
                                            cnt += 1
                                            return(
                                                <motion.a id={product.id} className="preview_wrap"
                                                    variants={{
                                                        hidden:{scale:0.9},
                                                        visible:{scale:1}
                                                        }}
                                                    transition={{ type: 'spring'}}>
                                                    {/* <img src={vegImg} alt='고사리_제품이미지' /> */}
                                                    <Title onClick={()=>window.open(product.rec_product_url,'_blank')} style={{cursor:'pointer'}}
                                                        className="regular mb-3 preview_title text-left" value={product.rec_product_name} />
                                                    <Title className="thin preview_description" value="마켓컬리" />
                                                    <span onClick={()=>recordFoodList.includes(item.food_id)?handleRemoveFoodlist(item.food_id):handleRecordFoodlist(item.food_id)} 
                                                        className="material-symbols-outlined" style={{background:recordFoodList.includes(item.food_id)?'cornflowerblue' : 'lightsalmon', color:'white'}}>{recordFoodList.includes(item.food_id)?'check':'shopping_cart'}</span>
                                                </motion.a>
                                            )}

                                    })}
                                    </ProductWrap>
                                </ProductScroll>
                            </motion.li>
                        :null}
                        </>
                        ))}
                        </motion.ul>
                    ))}
            </ItemBox>
        </FlexBox>
        </>
    )
}
export default BasketMarket;