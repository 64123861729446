import Spinner from 'react-bootstrap/Spinner';
import  ReactDOM  from "react-dom";
import { Backdrop } from './Modal';
import { Modal, ModalBox } from './Modal';
import Title from '../Title/Title';

const Loading = (props) => {
    return(
        <>
        {ReactDOM.createPortal(
            <Backdrop />,document.getElementById('backdrop-root') 
            )}
            {ReactDOM.createPortal(
                <Modal>
                    <ModalBox className='pb-5'>
                    <Title className="medium mb-4" value="로딩 중입니다" />
                    <div style={{display:'flex',flexWrap:'wrap', alignItems:'center', justifyContent:'center'}}>
                        <Spinner animation="border" variant="warning" />
                    </div>
                    </ModalBox>
                </Modal>,document.getElementById('modal-root') 
            )}
            </>
    )
}
export default Loading;