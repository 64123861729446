import styled from "styled-components";

export const RecordWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    width: 100%;
`

export const YearSelectBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top:0;
    background: rgb(255 255 255 / 80%);
    z-index: 1;
    padding: 16px 0 8px;
    backdrop-filter: blur(1rem);

    label{
        font-size: 0.8rem;
        font-weight: 300;
    }
    select{
        border: 1px solid #27322d;
        border-radius: 1rem;
        padding: 4px 8px;
        color: white;
        background: #27322d;
    }
`
export const DayRecordBox = styled.div`
    width: 55%;
    margin: 0 auto;
    @media all and (max-width:1024px){
        width: 100%;
    }
    ul{
        display: flex;
        flex-direction: column;
        li{
            background: #fafafa;
            padding: 16px;
            border-radius: 4px;
            margin: 4px 0;
        }
    }
    .shopday{
        // max-height: calc(100vh - 300px);
        max-height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        @media all and (max-width:1024px){
            max-height: fit-content;
        }
    }
        .shopday::-webkit-scrollbar{
            // display:none;
            width: 4px;
            @media all and (max-width:1024px){
                width: 2px;
            }
        }
        .shopday::-webkit-scrollbar-thumb {
            height: 30%; /* 스크롤바의 길이 */
            background: rgb(39 50 45 / 50%); /* 스크롤바의 색상 */
            border-radius: 10px;
        }
        
        .shopday::-webkit-scrollbar-track {
            background: rgb(39 50 45 / 10%);  /*스크롤바 뒷 배경 색상*/
        }
    .shopday li{
        cursor: pointer;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .regular{
            padding: 0px 4px;
        }
        .light{
            padding: 4px 4px 0;
        }
    }
`

export const DayEditBtn = styled.div`
    cursor: pointer;
    padding: 0 16px;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
`

export const RecordFlex = styled.div`
    display: flex;
    @media all and (max-width:1024px){
        flex-direction: column;
    }
`