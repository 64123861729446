import React, { useEffect,useState } from 'react';
import kakao_img from '../../lib/img/sns/kakao_login_large_wide.png';

const KakaoLogin = () => {
    const [loginMessage, setLoginMessage] = useState('');
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        if (!window.Kakao.isInitialized()) {
            window.Kakao.init('d6caa2e31d87e20eb139bf2f476b2f9b');
        }
        displayToken();
    }, []);

    const loginWithKakao = () => {
        window.Kakao.Auth.authorize({
            redirectUri: 'https://nuseum-market.com/user/kakao/callback/'
            // redirectUri: 'http://127.0.0.1:3000/user/kakao/callback/'
        });
    };

    const displayToken = () => {
        const token = getCookie('authorize-access-token');
        if (token) {
            window.Kakao.Auth.setAccessToken(token);
            window.Kakao.Auth.getStatusInfo(({ status }) => {
                if (status === 'connected') {
                    setLoginMessage('로그인 성공. 토큰: ' + window.Kakao.Auth.getAccessToken());
                    // fetchUserProfile();
                } else {
                    window.Kakao.Auth.setAccessToken(null);
                    setLoginMessage('');
                }
            });
        }
    };
    // const fetchUserProfile = () => {
    //     window.Kakao.API.request({
    //         url: '/v2/user/me',
    //         success: (response) => {
    //             setUserId(response.id);
    //             console.log('User ID: ' + response.id);
    //             console.log(response)
    //         },
    //         fail: (error) => {
    //             console.log('사용자 정보 요청 실패', error);
    //         }
    //     });
    // };


    const getCookie = (name) => {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    };



    return (
        <div>
            <img onClick={loginWithKakao} style={{width:'100%', height: '50px', objectFit:'cover', borderRadius:'4px', margin:0}} src={kakao_img} alt="카카오 로그인" />
            <div id="token-result"></div>
        </div>
    );
};

export default KakaoLogin;
