import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { recordActions } from '../../../../store/record-slice';
import { motion } from "framer-motion";
import { TypeAnimation } from 'react-type-animation';
import { userActions } from '../../../../store/user-slice';
import { BodyWrapper } from '../../../atom/Wrapper/Wrapper';
import Header,{ LoHeader } from '../../../atom/Header/Header';
import {useNavigate} from "react-router-dom";
import { FlexBox, Wrapperbox } from "../../../atom/Wrapper/Wrapper";
import { BasketNav, FoodList} from "./Basket.styled";
import Title from '../../../atom/Title/Title';
import Footer from "../../../atom/Footer/Footer";

import BasketRecipe from './BasketRecipe';
import BasketMarket from './BasketMarket';
import ShoppingCart from '../../../atom/Header/ShoppingCart';
import Btn from '../../../atom/Btn/Btn';
import { UserAfflictionTitle } from '../../User/UserAfflictionDetail';

const BasketFood = () => {
    const navigate = useNavigate();
    const [nav, setNav] = useState(0);
    const dispatch = useDispatch();
    const recommendaction = useSelector((state)=>state.user.recommend);
    const main_user_id = useSelector((state) => state.user.main_user_id);
    const main_user_recommend_food = useSelector((state) => state.user.user_recommend_food);

    const food_category_list = useSelector((state) => state.basic.food_category);
    const recordFoodList = useSelector((state) => (state.record.recordFoodList));
    
    useEffect(() => {
        const timerId = setTimeout(() => {
            dispatch(
                userActions.recommendaction({
                    recommend: true,
                })
            );
        }, 2000); // 1000 밀리초 (1초) 후에 실행
    
        // useEffect 클린업 함수에서 타이머 해제
        return () => clearTimeout(timerId);
    }, []); // 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행

    const [mainUserRecommendFoodsByCategory, setMainUserRecommendFoodsByCategory] = useState({});
    useEffect(()=>{
        let mainUserRecommendFoodsByCategory = {}

        if (Array.isArray(main_user_recommend_food)) {
            for (const item of main_user_recommend_food) {
                const category = item.food_category;
                if (!mainUserRecommendFoodsByCategory[category]) {
                    mainUserRecommendFoodsByCategory[category] = [];
                }
                mainUserRecommendFoodsByCategory[category].push(item);
            }
        }
        setMainUserRecommendFoodsByCategory(mainUserRecommendFoodsByCategory);
    },[main_user_recommend_food])

    
    const handleRecordFoodlist = (item_id) =>{
        dispatch(
            recordActions.recordFoodList({
                recordFoodList: [...recordFoodList, item_id]
            })
        )
    };
    const handleRemoveFoodlist = (item_id) => {
        dispatch(
            recordActions.recordFoodList({
                recordFoodList: recordFoodList.filter(item => item !== item_id)
            })
        );
    };

    return(
        <BodyWrapper bg="homebg">
            <Header 
                style={{position:'sticky', top: 0, zIndex: 1, background: '#fbfbfa'}}
                value={
                <LoHeader style={{justifyContent:'space-between'}}>
                    <Title onClick={()=>navigate("/")} className="logo" value="뉴지엄랩" />
                    <BasketNav>
                        <motion.div onClick={()=>setNav(0)} animate={nav===0?{background:'linear-gradient(to right, white 0%, #01716D 0%)',color:'white', fontWeight: 800,borderTopRightRadius:0,borderBottomRightRadius:0}:{background:'linear-gradient(to right, white 100%, #f38240 100%)',color:'#27322d',borderTopRightRadius:0,borderBottomRightRadius:0}}>식품</motion.div>
                        <motion.div onClick={()=>setNav(1)} animate={nav===1?{background:'linear-gradient(to right, #f38240 100%, white 100%)',color:'white', fontWeight: 800,borderTopLeftRadius:0,borderBottomLeftRadius:0}:{background:'linear-gradient(to right, #01716D 0%, white 0%)',color:'#27322d',borderTopLeftRadius:0,borderBottomLeftRadius:0}}>마켓</motion.div>
                        {/* alert('현재 준비중입니다! 마켓은 곧 오픈됩니다 :)') */}
                        {/* <motion.div onClick={()=>setNav(2)} style={nav===2?{background:'#27322d',color:'white', fontWeight: 800}:null}>식단</motion.div> */}
                    </BasketNav>
                    <ShoppingCart />
                </LoHeader>} />
            <Wrapperbox>
                {nav===0?
                    <FlexBox className="column_center" style={{position: 'relative'}}>
                        <UserAfflictionTitle />
                        <div className='mb-2' style={{width: '100%', padding:'4px 16px', display:'flex',justifyContent:'center'}}>
                            <Btn onClick={()=>navigate(`/about`)}
                                variant="secondary" size="sm" className="lightgray border-no" style={{minWidth: 'auto', borderRadius: '15px', padding: '8px 16px'}} value="건강 정보" />  
                        </div>
                        <FoodList style={{marginTop: '0'}}>
                            {/* food_category에 따라 그룹화하여 배열 */}
                            {mainUserRecommendFoodsByCategory && Object.keys(mainUserRecommendFoodsByCategory).map((category, index) => (
                                <div key={index} className='foodlistwrap'>
                                    {category === '10'?null: //시크릿은 제외
                                    <>
                                        <Title box={{width: '100%'}} className="medium mt-2 text-center" style={{fontSize:'1rem', color:'#01716d'}} value={food_category_list[+category - 1]} />
                                        {mainUserRecommendFoodsByCategory[category]?.map((food, foodIndex) => (
                                            <li key={foodIndex} style={{flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between', alignItems:'center', cursor:'pointer'}}>
                                                <div style={{maxWidth:'80%'}}>
                                                {recommendaction ? (
                                                    <span
                                                    style={{textDecoration:recordFoodList.includes(food.food_id)?'1px wavy line-through #27322d':'none', fontWeight:600}}
                                                    >
                                                    {food.food_name}
                                                    </span>
                                                ) : (
                                                    <TypeAnimation
                                                        sequence={[food.food_name, 1000]}
                                                        wrapper="span"
                                                        speed={60}
                                                        repeat={0}
                                                    />
                                                )}
                                                <div>
                                                    {food.nutro_names?.map((item)=>(
                                                        <span style={{padding:'0 2px', fontWeight:100, fontSize:'0.8rem', color:'rgba(83, 83, 83, 0.7)'}}>{item.name}</span>
                                                    ))}
                                                </div>
                                                </div>
                                                <span                                                 
                                                    // 장바구니에 넣을 수 있도록 추가
                                                    onClick={()=>recordFoodList.includes(food.food_id)?handleRemoveFoodlist(food.food_id):handleRecordFoodlist(food.food_id)} 
                                                    className="material-symbols-outlined check" style={{color:recordFoodList.includes(food.food_id)?'black':'#dbdbdb', borderLeft:'1px solid #eee'}}>check</span>
                                            </li>
                                        ))}
                                        {index !== Object.keys(mainUserRecommendFoodsByCategory).length - 1 && (
                                            <p style={{opacity:0}}>+---------------------------------------+</p>
                                        )}
                                    </>
                                    }
                                </div>
                            ))}

                        </FoodList>
                        <FoodList style={{border:'none', boxShadow:'none'}}>
                        <Title className="medium text-left mb-3" value="Food as medicine" />
                            <Title className="reular text-left mb-3" value="뉴지엄 마켓은 특별한 식이 및 영양 필요를 가진 사람들을 위해 맞춤형 해결책을 제공하는 전문 플랫폼입니다. 뉴지엄 마켓은 아동의 발달 문제, 키성장, 고혈압, 비만, 당뇨병, 고지혈증, 식품 알레르기, 유당 불내증, 과민성장증후군 등 다양한 건강 문제를 가진 사람들에게 맞춤 식단을 제공하여 그들의 건강을 지원합니다." />
                            <Title className="reular text-left mb-3" value="뉴지엄 마켓의 중심에는 과학적 연구에 기반한 건강 지향적인 제품과 식품을 제공하는 것에 있습니다. (주)뉴지엄랩은 모든 제품을 엄선하여, 이들이 높은 영양 기준을 만족시킬 뿐만 아니라 특정 건강 문제의 관리와 개선에 직접적으로 도움이 되도록 합니다." />
                            <Title className="reular text-left" value="뉴지엄 마켓은 검증된 식품 및 영양 정보를 바탕으로, 개인과 가족의 건강 데이터에 맞춰 실제 생활에 적용할 수 있는 제안을 만듭니다. 우리는 81,000개가 넘는 방대한 식품 및 영양 데이터베이스에서 영양적으로 가치가 높고 장내미생물의 건강에 좋은 영향을 미치는 식품 1,839개를 NUSEUM's Choice로 선별했습니다. 또한 소비자의 구매 데이터를 활용해 제안을 최적화하여 각 개인의 필요에 맞는 건강한 식단을 제공합니다." />
                        </FoodList>
                        <div>

                        </div>
                        <div className="mb-5" style={{width: '100%', maxWidth:'500px', padding:'0 16px'}}>
                            <Btn onClick={()=>navigate(`/about`)}
                                variant="secondary" size="lg" className="grayborder full" value="건강 정보 보러가기" />  
                        </div>
                        
                    </FlexBox>
                :nav===1?
                    <BasketMarket />
                :
                    <BasketRecipe />
                }
            </Wrapperbox>
            <Footer />
        </BodyWrapper>
    )}
export default BasketFood;