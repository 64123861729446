import {configureStore} from '@reduxjs/toolkit';
import authSlice from './auth-slice';
import userSlice from './user-slice';
import basicSlice from './basic-slice';
import recordSlice from './record-slice';
import analysisSlice from './analysis-slice';

const store = configureStore({
    reducer:{
        auth: authSlice,
        user: userSlice,
        basic: basicSlice,
        record: recordSlice,
        analysis: analysisSlice
    }
});

export default store;
