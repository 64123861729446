import styled from 'styled-components';

export const Wrapperwrap = styled.div`
    padding: 0 46px;
    width: 100%;
    @media all and (max-width:1024px){
        padding: 0 16px;
    }
`
export const Wrapperbox = styled.div`
    max-width: 1024px;
    margin: 0 auto;
    padding-bottom: 48px;
    .column_center{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .margin_auto{
        margin: 0 auto;
    }
    .row_center{
        display: flex;
        justify-content: center;
    }
    .column{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .column_stretch{
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    .wrap{
        flex-wrap: wrap;
    }
`
export const FlexBox = styled.div.attrs((props) => ({
    className: props.className
}))`

`

const BodyWrap = styled.div`
    min-height: 100vh;
    width: 100vw;
    .recordbg{
        background: rgb(223,232,241);
        background: linear-gradient(180deg, #f4efef 0%, rgba(255,229,226,1) 50%, rgba(223,232,241,1) 100%);
    }
    .analysisbg{
        background: #f4f5f8;
    }
    .aboutbg{
        background: #EFF2F9;
    }
    .mypagebg{
        background: #F2EFF4;
    }
    .homebg{
        background: #fffefd;
    }

    .blurbg{
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        background: rgb(255, 255, 255, 0.3);
    }
`

export const BodyWrapper = (props) => {
    return(
        <BodyWrap>
            <div
            style={{minHeight:'100vh', height:'100%'}}
            className={props.bg}>
                <div
                style={{minHeight:'100vh', height:'100%'}}
                className={props.className}>
            
                {props.children}
                </div>
            </div>
        </BodyWrap>
    )
}


const Wrapper = (props) => {
    return(
        <Wrapperwrap
        style={props.style}>
            <Wrapperbox
            style={props.box}
            >
                {props.children}
            </Wrapperbox>
        </Wrapperwrap>
    )
}
export default Wrapper;