import Header,{ LoHeader } from '../../atom/Header/Header';
import { useDispatch } from 'react-redux';
import Title from '../../atom/Title/Title';
import Wrapper, { FlexBox } from '../../atom/Wrapper/Wrapper';
import {DetailBox} from './Mypage/Mypage.styled'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { authActions } from '../../../store/auth-slice';
import  ReactDOM  from "react-dom";
import { Backdrop,Modal, ModalBox } from '../../atom/Modal/Modal';

const Block = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user_id = localStorage.getItem('id');


    const handleLogout = async() => {
      try{
          const response = await axios.delete('/user/auth/', {
              user_id: user_id
          },
          {headers: {
              "Content-Type": "application/json",}});

          if(response.data.code==='0000'){
              // window.sessionStorage.removeItem('isLoggedIn');
              // window.sessionStorage.removeItem('userId');
              localStorage.clear();
              dispatch(
                  authActions.logout(),
              )
              
              navigate('/');
          }
      }catch(error){
              alert('오류가 발생했습니다. 담당자에게 문의해주세요!');
          }
      };

    return(
      <>
        {ReactDOM.createPortal(
        <Backdrop />,document.getElementById('backdrop-root') 
        )}
        {ReactDOM.createPortal(              
            <Modal style={{top: 0}}>
                <ModalBox className='pb-5' style={{width:'100%', margin: 0, padding: '0.5rem', height:'100vh'}}>
                  <Header value={<LoHeader style={{justifyContent:'space-between'}}><Title className="medium dodum" value="뉴지엄랩" />
                    <span onClick={()=>handleLogout()} style={{paddingRight:'16px'}} className="material-symbols-outlined">exit_to_app</span></LoHeader>} />
          
                  <Wrapper box={{position: 'relative'}}>
                    <FlexBox className='column_center'>
                      <Title className="medium mb-2 mt-3" value="공지 사항" />
                      <DetailBox className='mt-4'>
                        <Title className="regular mt-2 text-left" value={`안녕하세요.
                          개인에게 맞추어진 영양정보 제공, 헬스케어 커머스 플랫폼 뉴지엄랩입니다.${'\n'}
                          현재 뉴지엄 마켓은 월구독 이용권을 구매하신 분들께 서비스를 진행하고 있습니다.🥦${'\n'}
                          당신의 영양생리약리 박물관 뉴지엄 마켓의 계속 이용을 원하신다면 홈페이지를 통해 주문해 주세요.${'\n'}
                          이용에 불편을 드려 죄송합니다.
                          감사합니다 😊`} />
                        <Title onClick={()=> window.open('https://www.nuseum-lab.com/')} className="regular mt-2 text-left" value="구매링크 ▶ https://www.nuseum-lab.com/" />
                      </DetailBox>
                      <Title className="semibold mb-2 mt-4" value="카카오 채널 @뉴지엄" />
                      <a id="chat-channel-button" href="javascript:chatChannel()">
                        <img src="https://developers.kakao.com/assets/img/about/logos/channel/consult_small_yellow_pc_2X.png"
                          style={{maxWidth:'120px'}}
                          alt="카카오톡 채널 채팅하기 버튼" />
                      </a>
                    </FlexBox>
                  </Wrapper>
                </ModalBox>
            </Modal>,document.getElementById('modal-root') 
        )}
        
      </>
    )
};
export default Block;  
