import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from "./store/auth-slice";
import { userActions } from "./store/user-slice";
import {recordActions} from './store/record-slice';
import {basicActions} from './store/basic-slice';
import axios from 'axios';

const InitialSetup = (props) => {
    const dispatch = useDispatch();
    // reducer에서 login 확인
    const token = localStorage.getItem('authToken');
    const user_id = localStorage.getItem('id');
    const expirationDate = localStorage.getItem('expirationDate');
    const isLoggedIn = useSelector((state)=>state.auth.isLoggedIn);
    const main_user_recommend_food = useSelector((state) => state.user.user_recommend_food);
    const main_user_recommend_product = useSelector((state) => state.user.user_recommend_product);
    const main_user_id = useSelector((state) => state.user.main_user_id);
    const useredit = useSelector((state) => state.user.useredit);
    const storedfoodData = localStorage.getItem('myFoodData');
    const storedproductData = localStorage.getItem('myproductData');

    const currentMonthRecord = useSelector((state) => state.record.monthlyData);

    // 현재 날짜, 월 불러오기
    const currentDate = new Date();
    const formattedDate = new Date().toISOString().split('T')[0];
    const currentMonth = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
    // 현재 월의 데이터가 store에  있는지 확인
    const currentMonthDataExists = currentMonthRecord[currentMonth] && currentMonthRecord[currentMonth].length > 0;
    const monthlyData = useSelector((state) => state.record.monthlyData);

    // allergy, incongruity, affliction 리스트 변수
    const all_affliction = useSelector((state) => state.basic.all_affliction);
    const all_incongruity = useSelector((state) => state.basic.all_incongruity);
    const all_allergy = useSelector((state) => state.basic.all_allergy);

    const is_active = useSelector((state) => state.user.is_active);


    const IncongruityList = async () => { //전체 (-) 리스트 조회하기(incongruity_get)
        try{
            const response = await axios.post('/user_info/api/v1/incongruity_get', {},
            {headers: {
                "Content-Type": "application/json",}});
            dispatch(
                basicActions.allincongruitysave({
                    all_incongruity: response.data.detail
                })
            )
        }catch(error){
            alert('데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
        };
    };

    const AllergyList = async () =>{ //전체 알러지 리스트 조회하기(allergy_get)
        try{
            const response2 = await axios.post('/user_info/api/v1/allergy_get',{},
            {headers:{
                "Content-Type" : "application/json",}});
            dispatch(
                basicActions.allallergysave({
                    all_allergy: response2.data.detail
                })
            )
        }catch(error){
            alert('데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
        }
    };

    const AfflictionList = async () => { //전체 (+) 리스트 조회하기(affliction_get)
        try{
            const response3 = await axios.post('/user_info/api/v1/affliction_get', {},
            {headers: {
                "Content-Type": "application/json",}});
            dispatch(
                basicActions.allafflictionsave({
                    all_affliction: response3.data.detail
                })
            )
        }catch(error){
            alert('데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
        };
    };

    
    // 서버에서 필요한 데이터 불러와서 redux store에 넣어두기
    useEffect(() => { 
        if(all_affliction.length == 1 || all_allergy.length == 1 || all_incongruity == 1){
            IncongruityList();
            AllergyList();
            AfflictionList();
        }
    },[]);

    console.log('?',expirationDate)
    //token 만료 시간 추가
    useEffect(()=>{
        if (new Date() >= new Date(expirationDate) || !expirationDate){
            // 토큰이 만료되었다면 초기화
            localStorage.removeItem('token');
            localStorage.removeItem('expirationDate');
        }
  
    },[expirationDate])

    //사전예약자 혹은 구매자만 이용가능하도록 is_active 조건 확인하는 API 호출
    const IsActiveCheck = async () => { //전체 (+) 리스트 조회하기(affliction_get)
        try{
            const response = await axios.post('/user/api/v1/is-active', {
                user_id: user_id
            },
            {headers: {
                "Content-Type": "application/json",}});
            dispatch(
                userActions.setisactive({
                    is_active: response.data.is_active
                })
            )
        }catch(error){
            alert('사용자 인증에 오류가 발생했습니다. 담당자에게 문의해주세요!');
        };
    };



    // 아이디가 저장되어 있다면, 이미 user가 있는 계정인지 확인 -> user_cards 가져오기
    const CardInfo = async()=>{
        dispatch(
            basicActions.setisLoading({
                isLoading: true
        }));
        try{
            const response = await axios.post("/user_info/api/v1/userinfo_list/", {
                user_id: user_id
            },
            {headers: {
                "Content-Type": "application/json",
            }});
            dispatch(
                userActions.cardsave({
                    userId : user_id,
                    card: JSON.stringify(response.data.user_cards),
                }),
            );
            dispatch(
                userActions.setmainuser({
                    main_user: response.data.user_cards[0].card_nickname
                })
            );
            dispatch(
                userActions.setmainuserid({
                    main_user_id: response.data.user_cards[0].id
                })
            );
            
        }catch(error){
            try{
                if(error.response.status !==400){
                    alert('사용자 로드 중 오류가 발생했습니다. 담당자에게 문의해세요');
                }
            }
            catch{}
        }finally{
            dispatch(
                userActions.setuseredit({
                    useredit : false
                })
            )
            dispatch(
                basicActions.setisLoading({
                    isLoading: false
            }));
        }
    };

    //사용자추천푸드 API
    const UserRecommendFood = async() => {
        dispatch(
            basicActions.setisLoading({
                isLoading: true
            }));
        // AbortController를 생성하고 타임아웃을 30초로 설정합니다.
        const controller1 = new AbortController();
        const timeoutId1 = setTimeout(() => controller1.abort(), 30000);

        let errorCaught = false; // 오류를 캐치했는지 여부를 추적하는 변수
        //사용자 추천 푸드 호출
        try{
            const response = await axios.post('/food/api/v1/user_recommend_food/', {
                is_active: true,
                user_id: user_id,
                user_card: main_user_id,
            },
            {headers: {
                "Content-Type": "application/json",},
                signal: controller1.signal, // AbortController를 fetch에 전달합니다.
            });
            // 타임아웃 전에 응답이 왔다면 clearTimeout을 호출하여 타임아웃을 취소합니다.
            clearTimeout(timeoutId1);
            
            // localstorage에 저장할 데이터
            const result = {
                user_id: user_id,
                user_card: main_user_id,
                data: response.data.details,
            };
            //로컬스토리지 저장
            localStorage.setItem('myFoodData', JSON.stringify(result));
            
            dispatch(
                userActions.setuserrecommendfood({
                    user_recommend_food: response.data.details
                })
            );
        }catch(error){
            // 오류를 캐치한 경우, 중복오류 메시지가 뜨지 않도록
            if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                errorCaught = true; // 오류를 캐치했음을 표시
                alert('식품 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
            }
        }finally{
            dispatch(
                basicActions.setisLoading({
                    isLoading: false
                })
            )
        };
    };

    // 현재 날짜의 월 사용자기록 불러오기
    const UserRecord = async() => {
        // AbortController를 생성하고 타임아웃을 30초로 설정합니다.
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 30000);
        let errorCaught = false; // 오류를 캐치했는지 여부를 추적하는 변수

        //사용자 추천 푸드 리스트 호출
        try{
            const response = await axios.post('/record/api/v1/user_record_get_month/', {
                user_card_id: main_user_id,
                year: currentDate.getFullYear(),
                month: currentDate.getMonth() + 1
            },
            {headers: {
                "Content-Type": "application/json",},
            signal: controller.signal, // AbortController를 fetch에 전달합니다.
            });
            // 타임아웃 전에 응답이 왔다면 clearTimeout을 호출하여 타임아웃을 취소합니다.
            clearTimeout(timeoutId);

            // 성공적인 응답 처리
            dispatch(
                recordActions.recordget({
                    month: currentMonth,
                    data: response.data
                })
            );
        }catch(error){
            try{
                if(error.response.status === 400){
                    errorCaught = true;
                    dispatch(
                      recordActions.recordget({
                          month: currentMonth,
                          data: []
                      })
                  );
                }
            }catch{
                if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                    try{
                        if(monthlyData[currentMonth]){}
                    }catch{
                        errorCaught = true; // 오류를 캐치했음을 표시
                        alert('사용자 기록 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                    }
                }
            }
        }
    };

    //사용자추천제품 API
    const UserRecommendProduct = async() => {
        dispatch(
            basicActions.setisLoading({
                isLoading: true
            }))
        // AbortController를 생성하고 타임아웃을 30초로 설정합니다.
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 30000);
        let errorCaught = false; // 오류를 캐치했는지 여부를 추적하는 변수
      
        //사용자 추천 제품 호출
        try{
            const response = await axios.post('/food/api/v1/user_recommend_product/', {
                user_id: user_id,
                user_card: main_user_id,
            },
            {headers: {
                "Content-Type": "application/json",},
            signal: controller.signal, // AbortController를 fetch에 전달합니다.
            });
            // 타임아웃 전에 응답이 왔다면 clearTimeout을 호출하여 타임아웃을 취소합니다.
            clearTimeout(timeoutId);

            dispatch(
                userActions.setuserrecommendproduct({
                    user_recommend_product: response.data.detail
                })
            );
            // localstorage에 저장할 데이터
            const result2 = {
                user_id: user_id,
                user_card: main_user_id,
                data: response.data.detail,
            };
            //로컬스토리지 저장
            localStorage.setItem('myproductData', JSON.stringify(result2));
        }catch(error){
            if(storedproductData == null){
                alert('제품 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
            }else{
                if(JSON.parse(storedproductData).user_card !== main_user_id){ //로컬에 추천제품 데이터가 있는데 main_user가 다를 떄 실행
                    alert('제품 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                }
            }
            errorCaught = true; // 오류를 캐치했음을 표시
        }finally{
            dispatch(
                basicActions.setisLoading({
                    isLoading: false
                }))
        }
    };

    //토근이 있다면 제일 먼저 실행
    useEffect(() => {
        //로컬스토리지 확인 후 redux store 저장(id, token, 사용자추천푸드(storedfoodData)
        if(token!==null){
            dispatch(
                authActions.login({
                    token: token,
                    userId: user_id,
                })
            )
            if(main_user_id !== 0){
                if(storedfoodData && JSON.parse(storedfoodData).data.length !== 0){
                    let errorCaught = false; // 오류를 캐치했는지 여부를 추적하는 변수
                    try{
                        if(JSON.parse(storedfoodData).user_card !== main_user_id){ //로컬에 추천식품 데이터가 있는데 main_user가 다를 떄 실행
                            UserRecommendFood();
                        }else{ //로컬에 있는 추천식품 데이터 redux store에 저장
                            dispatch(
                                userActions.setuserrecommendfood({
                                    user_recommend_food: JSON.parse(storedfoodData).data
                                })
                            )}
                    }catch(error){
                        // 오류를 캐치한 경우, 중복오류 메시지가 뜨지 않도록
                        if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                            errorCaught = true; // 오류를 캐치했음을 표시
                            alert('식품2 추천 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                        }
                    }

                }else{  //main_user_id가 있는데 로컬에 추천식품 데이터가 없다면
                    if(main_user_recommend_food && main_user_recommend_food.length === 0 ){
                        UserRecommendFood();
                    }
                }
            }else{  //토큰은 있는데 main_user가 없을 떄 시도
                CardInfo();
            }
        }else{
            localStorage.removeItem('authToken');
            localStorage.removeItem('id');
        }
    }, [token,main_user_id,isLoggedIn,storedfoodData]);

    useEffect(()=>{
        if(token!==null){
            IsActiveCheck();
        }
    },[is_active])

    useEffect(()=>{
        if(isLoggedIn&&main_user_id !== 0){
            if(storedfoodData && JSON.parse(storedfoodData).data.length !== 0){
                if(JSON.parse(storedfoodData).user_card !== main_user_id){
                    // pass
                }else{
                    if(storedproductData && JSON.parse(storedproductData).data.length !== 0){
                        if(JSON.parse(storedproductData).user_card !== main_user_id){
                            UserRecommendProduct();
                        }else{
                            dispatch(
                                userActions.setuserrecommendproduct({
                                    user_recommend_product: JSON.parse(storedproductData).data
                                })
                            )   
                        }
                    }else{
                        if(main_user_recommend_product && main_user_recommend_product.length === 0){
                            UserRecommendProduct();
                        }
                    }
                }
            }
        }
    },[main_user_id, storedfoodData, storedproductData]);

    useEffect(()=>{
        if(isLoggedIn&&main_user_id !== 0){
            //메인 사용자가 있다면 사용자 기록 불러오기
            if(!currentMonthDataExists){
                UserRecord();
            };
        }
    },[main_user_id,currentMonthDataExists]);

    useEffect(()=>{
        if(useredit == true){
            CardInfo();
        }
    },[useredit])
    return null;
};

export default InitialSetup;