import  {createSlice} from '@reduxjs/toolkit';

const initialState = {
    userId: null,
    card: '',

    main_user : '',
    main_user_id: 0,
    useredit: false,
    user_recommend_food: [],
    user_recommend_product: [],
    recommend: false, // home 추천 식품(오프라인) text action 감지(처음 들어올 때만 action되도록)

    //사전예약자 확인(승인)
    is_active: false
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        cardsave(state, action){
            state.userId = action.payload.userId;
            state.card = action.payload.card;
        },
        cardclaer(state){
            state.userId = null;
            state.card = '';
        },
        recommendaction(state,action){
            state.recommend = action.payload.recommend;
        },
        setmainuser(state,action){
            state.main_user = action.payload.main_user;
        },
        setmainuserid(state,action){
            state.main_user_id = action.payload.main_user_id;
        },
        setuserrecommendfood(state,action){
            state.user_recommend_food = action.payload.user_recommend_food;
        },
        setuserrecommendproduct(state,action){
            state.user_recommend_product = action.payload.user_recommend_product;
        },
        setuseredit(state,action){
            state.useredit = action.payload.useredit;
        },
        
        setisactive(state,action){
            state.is_active = action.payload.is_active;
        }
    }
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
