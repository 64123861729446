const Terms = () =>{

    return(
        <div style={{whiteSpace:'pre-line'}}>
        "이용약관"{'\n'}
        제1조 (목적) 본 이용약관은 "(주)뉴지엄랩"의 서비스 이용 및 제공에 관한 제반 사항의 규정을 목적으로 합니다.
        {'\n'}
        제2조 (정의)
        "서비스"란 회사가 제공하는 웹앱 및 관련 서비스를 의미합니다.
        "이용자"란 본 약관에 따라 서비스를 이용하는 자를 말합니다.
        "회원"이란 회사와 이용 계약을 체결한 자를 의미합니다.
        "비회원"이란 회원에 가입하지 않고 서비스를 이용하는 자를 의미합니다.
        {'\n'}
        제3조 (약관의 명시와 개정)
        회사는 이 약관을 서비스 초기 화면에 게시합니다.
        회사는 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
        변경된 약관은 공지하며, 이용자는 동의하지 않을 경우 서비스 이용을 중단할 수 있습니다.
        {'\n'}
        제4조 (서비스의 제공 및 변경)
        회사는 웹앱 서비스 및 관련 서비스를 제공합니다.
        회사는 서비스의 내용을 변경할 수 있으며, 이를 공지합니다.
        {'\n'}
        제5조 (서비스의 중단)
        회사는 시스템 점검 등의 사유로 서비스 제공을 일시적으로 중단할 수 있습니다.
        회사는 서비스 중단으로 인한 손해에 대해 책임지지 않습니다.
        {'\n'}
        제6조 (회원가입)
        이용자는 가입 양식에 따라 회원가입을 신청합니다.
        회사는 회원가입 신청을 승낙하며, 승낙 시점에 계약이 성립됩니다.
        {'\n'}
        제7조 (회원 탈퇴 및 자격 상실)
        회원은 언제든지 탈퇴를 요청할 수 있습니다.
        회사는 허위 등록 등 사유로 회원 자격을 제한하거나 상실시킬 수 있습니다.
        {'\n'}
        제8조 (이용자의 의무)
        이용자는 허위 정보 등록, 타인의 정보 도용, 회사의 정보 변경 등을 하지 않습니다.
        이용자는 회사와 타인의 지적 재산권을 침해하지 않습니다.
        {'\n'}
        제9조 (개인정보 보호)
        회사는 개인정보 보호법에 따라 이용자의 개인정보를 보호합니다.
        회사는 이용자의 동의 없이 개인정보를 제3자에게 제공하지 않습니다.
        {'\n'}
        제10조 (저작권)
        회사가 작성한 저작물의 저작권은 회사에 귀속됩니다.
        이용자는 회사의 사전 승낙 없이 저작물을 영리 목적으로 이용할 수 없습니다.
        {'\n'}
        제11조 (면책조항)
        회사는 천재지변 등 불가항력으로 인한 서비스 제공 불가 시 책임이 면제됩니다.
        회사는 이용자의 귀책사유로 인한 서비스 이용 장애에 대해 책임지지 않습니다.
        {'\n'}
        제12조 (분쟁 해결)
        회사는 이용자의 불만을 처리하기 위해 피해보상처리기구를 운영합니다.
        분쟁이 발생하면, 회사는 신속히 처리합니다.
        {'\n'}
        제13조 (준거법 및 재판관할)
        이 약관의 해석 및 분쟁 해결은 대한민국 법을 따릅니다.
        분쟁은 서울중앙지방법원을 관할 법원으로 합니다.
        {'\n'}{'\n'}
        "개인정보취급방침"{'\n'}
        본 개인정보보호 약관(이하 "약관")은 (주)뉴지엄랩이 회원 개인정보를 보호하기 위해 제공되는 서비스(이하 "서비스")의 이용과 관련하여 회원의 동의, 권리 및 의무를 규정합니다
        {'\n'}
        1. 개인정보 수집 및 이용
        (주)뉴지엄랩은 회원의 개인정보를 수집 및 이용합니다. 수집되는 개인정보의 종류 및 수집목적은 서비스 제공, 회원 관리, 고객 지원 등을 위한 것입니다.
        회원은 개인정보 제공에 동의하지 않을 경우, 일부 서비스 이용이 제한될 수 있습니다.
        {'\n'}
        2. 개인정보의 제3자 제공 및 공유
        회원의 개인정보를 제3자에게 제공하는 경우에는 명확한 동의 절차를 거쳐야 합니다. 개인정보보호법에서는 개인정보의 제3자 제공을 예외적으로 허용하는 경우를 명시하고 있으므로, 해당 예외사항을 고려하여 작성해야 합니다.
        {'\n'}
        3. 개인정보의 보유 및 삭제
        (주)뉴지엄랩은 회원의 개인정보를 회원 탈퇴 시까지 보유하며, 보유 기간 종료 혹은 회원 탈퇴 시에는 즉시 파기되거나 익명화 처리됩니다.
        다만, 관련 법령에 따라 보존이 필요한 경우에는 해당 법령에 따라 보관합니다.
        {'\n'}
        4. 개인정보 보호 조치
        (주)뉴지엄랩은 회원의 개인정보를 보호하기 위해 최선의 노력을 다합니다. 개인정보 보호를 위해 보안 시스템 및 절차를 강화하고 있으며, 외부로부터의 무단 접근, 누출, 변경 또는 손상으로부터 회원의 개인정보를 보호하기 위한 조치를 취합니다.
        {'\n'}
        5. 회원의 권리와 의무
        회원은 개인정보에 대한 열람, 정정, 삭제를 요청할 수 있습니다. 회원은 개인정보의 제공에 동의하지 않을 권리가 있으며, 이 경우 일부 서비스 이용이 제한될 수 있습니다.
        회원은 약관에 명시된 개인정보 처리 방침에 동의하는 것을 전제로 서비스를 이용해야 합니다.
        {'\n'}
        6. 개인정보보호 관련 문의
        개인정보보호 관련 문의사항은 아래 연락처로 문의하실 수 있습니다: (주)뉴지엄랩{'\n'}
        카카오톡 @뉴지엄{'\n'}
        주소 (08812) 서울특별시 관악구 호암로 24길 6. 서울대학교 캠퍼스타운 창업 HERE-RO5 3층 306 뉴지엄랩{'\n'}
        이메일 nuseum@nuseum-lab.com
    </div>
    )
}
export default Terms;