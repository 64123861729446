import React, { useEffect, useState } from 'react';
import item_01 from '../../../lib/img/채소.png';
import item_02 from '../../../lib/img/과일.png';
import item_03 from '../../../lib/img/콩.png';
import item_04 from '../../../lib/img/통곡물.png';
import item_05 from '../../../lib/img/버섯.png';
import item_06 from '../../../lib/img/해조류.png';
import item_07 from '../../../lib/img/견과.png';
import item_08 from '../../../lib/img/축산.png';
import item_09 from '../../../lib/img/유제품.png';
import { CircleContainer } from './EatFoodLayout.styled';

const EatFoodLayout = (props) => {
    const items = [item_01,item_02,item_03,item_04,item_05,item_06,item_07,item_08,item_09]
    const [lack_index, setLackIndex] = useState([])

    useEffect(()=>{
        try{
            let arr = [props.analysisData[props.targetDate][0].categories][0].map(Number)
            setLackIndex(arr
                .filter(item => item !== 10)
                .map(item => item - 1));
        }catch{}
    },[props.analysisData,props.targetDate])
    
    const [score, setScore] = useState(0);

    useEffect(()=>{
        setScore(Math.floor((items.length-(items.length-lack_index.length)) / items.length * 100));
    },[lack_index, props.analysisData])
    
    return(
        <CircleContainer>
            {score}점
            {items.map((item,index) => {
                if(lack_index.includes(index)){
                    return(
                        <div key={index} className='circle-item'>
                            <img src={item} className='circle-item-img lack' alt='' />
                        </div>
                    )
                }else{
                    return(
                        <div key={index} className='circle-item'>
                            <img src={item} className='circle-item-img' alt='' />
                        </div>
                    )
                }
            })}
        </CircleContainer>
    )
}
export default EatFoodLayout;