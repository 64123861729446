import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollRestoration';
import {Home, BfHome} from './components/pages/Home/Home';

import NotFound from './components/pages/NotFound/NotFound';
import SignUp from './components/pages/Register/Signup';
import LogIn from './components/pages/Register/Login';
import { AddUser } from './components/pages/User/AddUser';
import Mypage from './components/pages/User/Mypage/Mypage';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Analysis from './components/pages/Analysis/Analysis';
import Record from './components/pages/Record/Record';
import About from './components/pages/About/About';
import Inquiry from './components/pages/User/Mypage/Detail/Inquiry';
import RecordDay from './components/pages/Record/RecordDay';
import Loading from '../src/components/atom/Modal/Loading';
import { UserAfflictionDetail } from './components/pages/User/UserAfflictionDetail';
import Block from './components/pages/User/Block';
import PasswordReset from './components/pages/Register/PasswordReset';
import Subscription from './components/pages/User/Subscription/Subscription';
import PasswordCheck from './components/pages/Register/PasswordCheck';
import Pay from './components/pages/User/Subscription/Pay';
import KakaoLogin from "./components/pages/Register/SNSLogin";


const Router = (props) => {

    const isLoggedIn = useSelector((state)=>state.auth.isLoggedIn);
    const isLoading = useSelector((state) => state.basic.isLoading);
    const isActive = useSelector((state) => state.user.is_active);


    return (
        <BrowserRouter>
            <ScrollToTop />
            {isLoading?
                <Loading />
            :null}

            {isLoggedIn?
                isActive? null:
                <>
                <Block />
                </>
            :null
            }

            <Routes>
                <Route element={<LogIn />} path='/login' />
                <Route element={<LogIn />} path='/user/kakao/callback' />
                <Route element={<PasswordReset />} path='/pwreset' />
                <Route element={<PasswordCheck />} path='/pwreset/check' />
                <Route element={isLoggedIn?<Subscription />:<NotFound />} path='/subscription' />
                <Route element={<Pay />} path='/subscription/pay/:pack' />
                <Route element={isLoggedIn?<Home />:<BfHome />} path='/' />
                <Route element={isLoggedIn?<Analysis />:<NotFound />} path='/analysis' />
                <Route element={isLoggedIn?<Record />:<NotFound />} path='/record' />
                <Route element={isLoggedIn?<RecordDay />:<NotFound />} path='/record/:number' />
                <Route element={isLoggedIn?<Mypage />:<NotFound />} path='/mypage/:id' />
                <Route element={isLoggedIn?<Inquiry />:<NotFound />} path='/mypage/inquiry/:id' />
                <Route element={isLoggedIn?<AddUser />:<NotFound />} path='/adduser' />
                <Route element={isLoggedIn?<UserAfflictionDetail />:<NotFound />} path='/affdet/:id' />
                <Route element={isLoggedIn?<About />:<NotFound />} path='/about' />
                <Route element={<SignUp />} path='/signup' />
                <Route path={'*'} element={isLoggedIn?<NotFound />:<Navigate to="/login" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
