import React, { useEffect, useState } from 'react';
import Btn from '../../../atom/Btn/Btn';
import Title from '../../../atom/Title/Title';

const NicePaySubscription = (props) => {
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [pw, setPW] = useState('');
  const [subscriptionAmount, setSubscriptionAmount] = useState(1004);
  const [isAgreed, setIsAgreed] = useState(false);  

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://pay.nicepay.co.kr/v1/js/";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSubscription = () => {
    if (!isAgreed) {
      alert('구매 진행에 동의해야 합니다.');
      return;
    }

    if (window.AUTHNICE) {
      window.AUTHNICE.requestPay({
        clientId: 'R2_bbd894b490f6492a8fbbf6ad6841c3b4',
        method: 'card',
        orderId: 'a8965034-763e-4c39-8c91-37b0926a737f',
        amount: subscriptionAmount,
        goodsName: '정기구독상품',
        returnUrl: 'http://nuseum.pe.kr/subscriptionAuth',
        cardNumber: cardNumber,
        expiryDate: expiryDate,
        birthDate: birthDate,
        fail: (result) => {
          alert('결제 실패: ' + result.errorMsg);
        }
      });
    } else {
      alert('나이스페이 스크립트가 로드되지 않았습니다.');
    }
  };

  return (
    <div style={{display:'flex', flexDirection:'column'}}>
      <div>
        <label style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
            <Title className="light" value='카드 번호*' />
            <input 
                style={{minWidth:'100%'}}
                type="text" 
                value={cardNumber} 
                onChange={(e) => setCardNumber(e.target.value)} 
            />
        </label>
      </div>
      <div className='mt-2'>
        <label style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
          <Title className="light" value='만료일(YYYYMM)*' />
          <input 
            type="text" 
            value={expiryDate} 
            onChange={(e) => setExpiryDate(e.target.value)} 
          />
        </label>
      </div>
      <div className='mt-2'>
        <label style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
          <Title className="light" value='비밀번호 앞2자리*' />
          <input 
            type="text" 
            value={pw} 
            onChange={(e) => setPW(e.target.value)} 
          />
        </label>
      </div>
      <div className='mt-2'>
        <label style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
          <Title className="light" value='생년월일 6자리 또는 사업자등록번호*' />
          <input
            style={{minWidth:'80%'}} 
            type="text" 
            value={birthDate} 
            onChange={(e) => setBirthDate(e.target.value)} 
          />
        </label>
      </div>
      <div className='mt-2'>
        <label style={{display:'flex'}}>
          <input 
            type="checkbox" 
            checked={isAgreed} 
            onChange={(e) => setIsAgreed(e.target.checked)} 
          />
          <Title className="light" value='구매 진행에 동의합니다.*' />
        </label>
      </div>
      <Btn onClick={handleSubscription} variant="primary" size="lg" type="submit" className={`full ${props.choice == 'basic'?'pinegreen':'lightsalmon'} mt-4 mb-3`} value="결제하기" />
      <div id="token-result"></div>
    </div>
  );
};

export default NicePaySubscription;
