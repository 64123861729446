import  {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isLoggedIn: false,
    token: null,
    userId: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action){
            state.isLoggedIn = true;
            state.token = action.payload.token;
            state.userId = action.payload.userId;
        },
        logout(state){
            state.isLoggedIn = false;
            state.token = null;
        },
        snslogin(state,action){
            state.isLoggedIn = true;
            state.token = action.payload.token;
            state.userId = action.payload.token;
        }
    }
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
