import { createSlice } from "@reduxjs/toolkit";

const currentDate = new Date();
const currentMonth = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;

const initialState = {
    monthlyData: {
        [currentMonth]: [],
        // 나머지 월들도 필요에 따라 추가할 수 있습니다
    },
    recordFoodList: [],
};
  
const RecordSlice = createSlice({
    name: "record",
    initialState,
    reducers: {
      recordget(state, action){
        const { month, data } = action.payload;
        // 이미 해당 월에 데이터가 존재하는지 확인
        if (!state.monthlyData[month] || state.monthlyData[month].length === 0) {
          // 해당 월에 데이터가 없으면 새로 추가
          state.monthlyData[month] = [data];
        }
      },
      recordFoodList(state,action){
        state.recordFoodList = action.payload.recordFoodList;
      },
      recordFoodListClear(state){
        state.recordFoodList = []
      }
    }
  });
  
  export const recordActions = RecordSlice.actions;
  export default RecordSlice.reducer;