import { styled } from 'styled-components';
import './Modal.css';

export const Backdrop = (props) => {
    return(
        <>
            <div className="backdrop" />
        </>
    )
}

export const Modal = styled.div`
    position: fixed;
    top: 10vh;
    left: 0;
    width: 100%;
    z-index: 100;
    overflow: hidden;
    .p-32{
        padding: 32px;
        @media all and (max-width:500px){
            padding: 20px;
        }
    }
`
export const ModalBox = styled.div`
    background: white;
    padding: 1.5rem 1.5rem 1rem;
    width: 300px;
    margin: 1rem auto;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
`