import { BodyWrapper } from "../../atom/Wrapper/Wrapper";
import {useForm} from 'react-hook-form';
import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { LoginBox, FormBox, ErrBox } from "./Register.styled";
import Btn from "../../atom/Btn/Btn";
import Title from "../../atom/Title/Title";
import axios from "axios";


const PasswordReset = (props) => {
    const navigate = useNavigate();
    const [emailToCheck, setEmailToCheck] = useState(''); // 이메일 인증코드 확인
    const [emailSend, setEmailSent] = useState(false);
    const {
        register,
        formState: {errors},
    } = useForm();

    const sendEmailVerification = async(email) => {
        if(emailToCheck === ''){
            alert('뉴지엄랩에 가입한 이메일을 입력해주세요')
        }else{
            try{
                setEmailSent(true)
                const response = await axios.post('/user/password_reset/', {
                    user_email: emailToCheck
                },{headers: {
                    "Content-Type": "application/json",}});
                navigate('/pwreset/check')
                // if(response.data.code==='0001'){
                //     alert('사용가능한 아이디 입니다 :)')
                // }else if(response.data.code==='0002'){
                //     alert('이미 사용중인 아이디 입니다!')
            }catch(error){
                if(error.response.data.message === 'User not found'){
                    alert('이메일이 틀렸거나, 가입하신 이메일이 아닙니다.')
                }else{
                    alert('네트워크 오류가 발생햇습니다. 카카오@ 뉴지엄으로 문의 부탁드립니다')
                }
                setEmailSent(false)
            }
        }
    }

    return(
        <BodyWrapper>
            <LoginBox>
                <Title onClick={()=>navigate("/")} className="medium mt-4" value="비밀번호 재설정" />
                <Title className="light mt-2" value="이메일 본인인증 후 비밀번호 재설정이 가능합니다 📨" />
                <Title className="thin mt-2" value="뉴지엄랩에 가입하신 이메일을 입력해주세요." />
                <Title className="thin mb-3" value="메일이 없다면 스팸메일함을 확인해주세요." />
                <FormBox>
                    <Form.Group className="mb-4 row-flex">
                    <span class="material-symbols-outlined">mail</span>
                        <Form.Control
                            id="user_email"
                            {...register('user_email', {
                                required: '이메일을 입력해주세요',
                                pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: '올바른 이메일 형식을 입력해주세요.',
                                disabled: true,
                                },
                            })}
                            placeholder='000@naver.com' type='email'
                            name="user_email"
                            error={errors.user_email}
                            onChange={(event)=>setEmailToCheck(event.target.value)}
                            />
                    </Form.Group>
                    {!emailSend?
                            <Btn size="lg" type="button" className="primary full" onClick={sendEmailVerification} value="인증코드 전송" />
                        :<Btn size="sm" type="button" className="lightgray" onClick={sendEmailVerification} value="인증코드 재전송" />}
                    {errors.user_email ? (<ErrBox>{errors.user_email.message}</ErrBox>):null}
                    <Btn onClick={()=>navigate('/login')}
                        style={{border:'none'}}
                        variant="secondary" size="sm" className="grayborder" value="돌아가기" />
                    <div style={{width: '100%', textAlign:'center'}}>
                        <a id="chat-channel-button" href="javascript:chatChannel()">
                            <img src="https://developers.kakao.com/assets/img/about/logos/channel/consult_small_yellow_pc_2X.png"
                                style={{maxWidth:'80px'}}
                                alt="카카오톡 채널 채팅하기 버튼" />
                        </a>
                    </div>
                </FormBox>
            </LoginBox>
        </BodyWrapper>
    )
}
export default PasswordReset;
