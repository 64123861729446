import Wrapper from "../../atom/Wrapper/Wrapper";
import { useState } from "react";
import { FlexBox } from "../../atom/Wrapper/Wrapper";
import Card from 'react-bootstrap/Card';
import Btn from '../../atom/Btn/Btn';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CardBox, CardBoxTitle,CardImg, CardBoxText } from './Mypage/Mypage.styled'; 
import Title from "../../atom/Title/Title";
import Button from 'react-bootstrap/Button';
import logo_profile from '../../lib/img/nuseum+logo.png';
import UserEdit from "./UserEdit";
import logo from '../../lib/img/nuseum+logo.png';
import { AboutTitle,AboutSection,AboutLogoBox } from "../About/About.styled";


const UserList = (props) =>{
    // 스토어에서 user_cards
    const UserCards = useSelector((state)=> state.user.card);
    let UserCardsArray = UserCards?JSON.parse(UserCards):null;

    //user update : edit page
    const [useredit, setUserEdit] = useState('');
    // 나이 계산
    const currentDate = new Date();

    // 오프캔버스
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    const options = [
        {
          name: 'Enable backdrop (default)',
          scroll: false,
          backdrop: true,
        }]

    return(
        UserCards===null || UserCards === ''?
            <Wrapper>

            <FlexBox className='column_center mt-4' style={{marginBottom: '80px'}}>
                    <AboutTitle>
                        <Title className="thin mt-2" value="소개" />
                    </AboutTitle>
                    <AboutSection className='mb-5'>
                        <AboutLogoBox>
                            <div className='about_logo'>
                                <Title className="black text-left mt-2" value={`NUSEUM`} />
                                <Title className="medium text-left mb-2" value={`Your Museum of Nutrient Physiology & Pharmacology`} />
                                <Title className="regular text-left" value="당신의 영양생리약리박물관에서 무엇을 먹어야할지 큐레이션 받으세요. 주식회사 뉴지엄랩은 영양과 건강의 더 나은 통합을 지원하고, 고객이 건강한 식품을 선택할 수 있도록 구매 단계에서 지원합니다." />                            
                            </div>
                            <img src={logo} alt='뉴지엄 로고' />
                        </AboutLogoBox>
                    </AboutSection>
                <Card bg={'light'} text={'dark'} style={{ width: '18rem' }} className="mb-4 column_center margin_auto" >
                    <Card.Body className='column_center'>
                        <Card.Title>                    
                            <span style={{fontSize:'5rem', color: '#888888', padding: '0.5rem', margin: '1rem 0'}}
                        className="material-symbols-outlined">person</span></Card.Title>
                        <Card.Text>
                            사용자를 추가해주세요
                        </Card.Text>
                    </Card.Body>
                    </Card>
                    <Link to='/adduser'>
                        <Btn variant="primary" size="lg" className="center primary" value="사용자 만들기" /> 
                    </Link>
            </FlexBox>
            </Wrapper>
        :
            <FlexBox className='column mt-4' style={{display: 'flex', flexWrap:'wrap'}}>
                {UserCardsArray&&UserCardsArray.map((arr, index)=>(
                    <Card key={index} bg={'light'} text={'dark'} style={{ width: '100%', boxShadow:'0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02)' }} className="mb-5 margin_auto" >
                        <Card.Header style={{background:'#01716D', color:'white'}}>내 정보</Card.Header>
                        <Card.Body className='row_center' style={{position: 'relative', background:'white'}}>       
                        <CardBox>      
                            <>
                                <Button style={{position: 'absolute', right: '-0.5rem', top:'0.5rem', background: 'transparent',border:'none'}}
                                id={index} variant="primary" onClick={(e) => {
                                    setUserEdit(arr)
                                    toggleShow();}} className="me-2">
                                    <span class="material-symbols-outlined">edit</span>
                                </Button>
                                <UserEdit show={show} onHide={handleClose} placement="bottom"
                                    useredit={useredit} />
                                
                            </>

                            <CardBoxTitle>
                                <CardImg src={logo_profile} alt='user_img' />
                                <Card.Text>
                                    <Title style={{margin: '4px 0'}} className="bold text-left" value={arr.card_nickname} />
                                    <div>
                                        <Title className="regular text-left" 
                                            value={`만 ${
                                                currentDate.getMonth() > parseInt(arr.user_birthday.substring(4, 6))  ||
                                                (currentDate.getMonth() === parseInt(arr.user_birthday.substring(4, 6)) && currentDate.getDate() >= parseInt(arr.user_birthday.substring(6,8)))
                                                ?
                                                currentDate.getFullYear() - parseInt(arr.user_birthday.substring(0,4))
                                                :
                                                currentDate.getFullYear() - parseInt(arr.user_birthday.substring(0,4)) - 1
                                            }세 ${arr.user_gender==='F'?'여자':'남자'}`} />
                                        <Title className="light text-left" value={`${arr.user_birthday.substring(0,4)}.${arr.user_birthday.substring(4,6)}.${arr.user_birthday.substring(6,8)}`} />
                                    </div>
                                </Card.Text>
                            </CardBoxTitle>
                                <CardBoxText>
                                    <Card.Text>
                                        <Title className="medium w_800" value='건강고민' />
                                        <div className="and">
                                            {arr.user_affliction?.map((aff,index)=>(
                                                <Title className="regular" key={index} value={aff.affliction} />
                                            ))}
                                            {arr.user_incongruity?.map((incon,index)=>(
                                                // 과민성장증후군 & 유당불내증만 보이게
                                                incon.incongruity_id == 7 | incon.incongruity_id == 8?
                                                <Title className="regular" key={index} value={incon.incongruity} />:
                                                null
                                            ))}
                                        </div>
                                    </Card.Text>
                                    {arr.user_allergy?
                                        <Card.Text>
                                            <Title className="medium w_800" value='식품알러지' />
                                            <div className="and">
                                            {arr.user_allergy.map((allergy,index)=>(
                                                <Title className="regular" key={index} value={allergy.allergy} />
                                            ))}
                                            </div>
                                        </Card.Text>
                                    :null}

                                    <Card.Text>
                                        <Title className="medium w_800" value='키 | 몸무게' />
                                        <Title className="regular" key={index} value= {`${arr.user_height}cm | ${arr.user_weight}kg`} />
                                    </Card.Text>
                                </CardBoxText>
                        </CardBox>    
                        </Card.Body>
                    </Card>
                ))}
            </FlexBox>
    )
}
export default UserList;