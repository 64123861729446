import styled from "styled-components";
import { motion } from 'framer-motion';

export const BasketNav = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    max-width: 350px;
    height: 28px;
    border-radius: 14px;
    border: 1px solid #27322d;
    @media all and (max-width:479px){
        width: 50%;
    }
    div{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-weight: 600;
        padding: 0 10px 0 10px;
        word-break:keep-all;
        border-radius: 14px;
        cursor: pointer;
    }
`
export const FoodNavWrap = styled.div`
    max-width: 100vw;
    width: 100%;
    overflow-x: scroll;
    background-color: white;
    position: sticky;
    top: 67px;
    display: flex;
    justify-content: center;
    z-index: 1;
    &::-webkit-scrollbar{
        display: none; /* Chrome, Safari, Opera*/
    }
    @media all and (max-width: 1024px){
        justify-content: flex-start;
        top: 44px;
    }
`
export const FoodNavTitle = styled.nav`
    display: flex;
    border-bottom: 1px solid #eee;
    width: 720px;
    background-color: white;
    align-items: center;
    border-radius: 5px;
    span{
        padding: 20px 0px 8px;
        cursor: pointer;
        width: 80px;
        text-align: center;
        border-radius: 1px;
        &:hover{
            font-weight: 800;
        }
    }
    .focus{
        border-bottom : 3px solid #13606c;
        font-weight: 900;
        color: #13606c;
    }
`

export const FoodList = styled.ul`
    background: white;
    margin: 1rem 16px;
    padding: 1rem;
    width: calc(100% - 32px);
    max-width: 500px;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    // border: 1px solid #dbdbdb;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px;
    justify-content: center;
    @media all and (max-width:1024px){
    }
    .foodlistwrap{
        width: 80%;
        display: flex;
        flex-direction: column;
        flex-wrap:wrap;
        justify-content: space-between;
        align-items: flex-start;
        @media all and (max-width:1024px){
            max-width: 286px;
            width: 100%;
        }
    }
    span::after{
        opacity: 0;
    }
    .medium{
        padding-bottom: 4px;
    }
    li{
        width: 100%;
        border-bottom: 1px solid #eee;
        padding: 2px 0;
        padding-left: 16px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 34%;
        @media all and (max-width:1024px){
            width: 100%;
            padding-left: 8px;
        }

        .light{
            padding: 4px 0;
        }
        p{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            padding: 4px 0;
        }
    }
    span{
        line-height: 1.8;
        white-space: pre-line;
        display: inline-block;
        font-size: 1rem;
        padding-top: 4px;
        font-weight: 500;
        @media all and (max-width:1024px){
        }
    }
    .check{
        padding: 4px 16px;
        height: 100%;
        display: flex;
        align-items:center;
    }
`

export const ItemBox = styled.ul`
    margin-left: 48px;
    @media all and (max-width: 1024px){
        margin-left: 0;
    }
    ul{
        max-width: 90vw;
        margin: 0;
        padding: 16px 16px 0;
        border-radius: 4px;
        width: calc(100%);
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        @media all and (max-width: 1024px){
            padding: 16px;
            margin: 16px 0;
            max-width: calc(100vw );
            box-shadow: 0 3px 0 2px #eeeeee75;
            border-bottom: 1px solid #eee;
        }
    }
    ul:last-child{
        box-shadow: none;
        border: none;
    }
    .semibold{
        // 음식 이름 더 굵게
        font-weight: 900;
    }
    .basket_recipe{
        
    }
    li{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 48%;
        margin-left: 2%;
        align-items: flex-start;
    }
    .product_wrap{
        width: 100%;
        display: inline-block;
        margin-bottom: 2rem;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
    }
`
export const ProductScroll = styled.div`
    width: 100%;
    overflow-x: scroll;
    // 임시 추가 (한 제품만 보이도록)
    >div:not(:first-of-type) {
        display:none
    }
    &::-webkit-scrollbar{
        display: none; /* Chrome, Safari, Opera*/
    }
`
export const ProductWrap = styled(motion.div)`
    width: fit-content;
    display: inline-block;
    margin-bottom: 1rem;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: no-wrap;
    @media all and (max-width:479px){
        justify-content: space-between;
    }
    // 제품 하나만 보이도록 임시 block
    a:nth-child(n+2){
        display:none;
    }
    a{
        display: inline-block;
        margin: 16px 16px 16px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 10px;
        border: 1px solid #a9a9a954;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 5px;
        color: #4a4a4a;
        max-width: calc(350px + 1rem);
        width: calc(50vw - 22px);
        position: relative;
        @media all and (max-width:479px){
            margin: 8px 0;
            width: calc(40vw - 8px);
        }
        span{
            position: absolute;
            bottom: 8px;
            right: 4px;
            font-size: 1.2rem;
            border-radius: 4px;
            padding: 4px;
            margin: 0 8px;
            cursor: pointer;
        }
    }
    img{
        /* max-width: 350px;  */
        max-height: 180px;
        height: 180px;
        object-fit: cover;
        object-position: 50% 30%;
        width: 100%;
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 16px;
        @media all and (max-width:479px){
            max-height: 160px;
        }
    }
    .preview_title{
        padding: 1rem;
        @media all and (max-width:479px){
            padding: 10px;
        }
    }
    .preview_description{
        padding: 0 1rem 1rem;
        @media all and (max-width:479px){
            padding: 0 1rem 40px;
        }
    }
`