import styled from 'styled-components';

export const HeaderBox = styled.div`
    padding: 16px 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    // border-bottom: 1px solid #dbdbdb;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid #ececec;
    // background: #fdfdf4; 라임색
    background: transparent;
    @media all and (max-width:1024px){
        min-height: 44px;
        padding: 0 0 0 16px;
    }
    @media all and (max-width:500px){
        max-width: 500px;
        min-height: 44px;
    }
`
export const HeaderWrap = styled.div`
    max-width: 1024px;
    width: 100%;

`
export const BfHeader = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    @media all and (max-width:1024px){
        padding: 16px 16px 16px 0;
    }
    justify-content: space-between;
    span{
        font-size: 2rem;
        cursor: pointer;
        @media all and (max-width:479px){
            font-size: 1.8rem;
        }
    }
`
export const LoHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    align-items: center;
`

const Header = (props)=>{
    return(
        <HeaderBox style={props.style}>
            <HeaderWrap>
                {props.value}
            </HeaderWrap>
        </HeaderBox>
    )
}
export default Header;