import Wrapper, { BodyWrapper, FlexBox } from "../../atom/Wrapper/Wrapper";
import Title from "../../atom/Title/Title";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import styled from "styled-components";
import { HealthBox } from "./User.styled";
import Btn from "../../atom/Btn/Btn";
import AfflictionBubble from "../Analysis/chart/Affliction_Bubble";


export const UserAfflictionBox = styled.div`
    // == RecordDayBox
    ul{
        display: flex;
        flex-wrap: wrap;
        @media all and (max-width:1024px){
            flex-direction: column;
        }
        li{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: white;
            padding: 16px;
            border-radius: 4px;
            text-align: left;
            width: 48%;
            margin: 1%;
            @media all and (max-width:1024px){
                width: 100%;
                margin: 16px 0;
            }
        }
        .medium{
            text-align: left;
        }
        .regular{
            text-align: left;
            padding: 1rem;
        }
        .bubblechartwrap{
            border: 1px solid #eee;
            background: #fbfbfb;
        }
`

export const UserAfflictionTitle = (props) =>{
    // 스토어에서 user_cards
    const UserCards = useSelector((state)=> state.user.card);
    let UserCardsArray = UserCards?JSON.parse(UserCards):null;
    // 사용중인 사용자카드 
    const main_user = useSelector((state) => state.user.main_user);
    const main_user_detail = UserCardsArray?.find(item => item.card_nickname === main_user);

    return(
        <FlexBox className="column_center wrap">
            <Title className="medium mt-3" value={`${UserCards?main_user:null}님을 위한 맞춤식품`} />
            <div style={{display:'flex', alignItems:'center', flexWrap:'wrap', justifyContent:'center'}}>
                
            {/* 유당불내증, 과민성장증후군만 보이도록 */}
            {/* {main_user_detail?.user_incongruity?
                main_user_detail.user_incongruity.map((item,index, last) => (
                    <>
                        {item.incongruity_id === "7" | item.incongruity_id === "8"?
                            <Title key={index} style={{margin:'0 2px'}} className="bold mb-1" value={`${item.incongruity}${','}`} />
                                :null
                        }
                    </>
            )):null} */}
            {/* 사용자가 선택한 고민들이 보이도록 */}
            {/* {main_user_detail?.user_affliction?
                main_user_detail.user_affliction.map((item,index, last) => (
                    <Title key={index} style={{margin:'0 2px'}} className="bold mb-1" value={`${item.affliction}${index + 1=== last.length ?'':','}`} />
                ))
            :null} */}

            {/* <Title className="medium" value="맞춤식품" /> */}
            </div>
        </FlexBox>
    )
}


export const UserAfflictionDetail = (props) => {
    const navigate = useNavigate();
    // 스토어에서 user_cards
    const UserCards = useSelector((state)=> state.user.card);
    let UserCardsArray = UserCards?JSON.parse(UserCards):null;
    // 사용중인 사용자카드 
    const main_user = useSelector((state) => state.user.main_user);
    const main_user_detail = UserCardsArray?.find(item => item.card_nickname === main_user);
    const aff_bubble_content = {'1':[{'name': '수분', 'value': 1},{'name': '저열량', 'value': 1},{'name': '단백질', 'value': 1},{'name': '식이섬유', 'value': 1}],
    '2':[{'name': '단백질', 'value': 1},{'name': '식이섬유', 'value': 1}],
    '3':[{'name': '단백질', 'value': 1},{'name': '식이섬유', 'value': 1}],
    '4':[{'name': '단백질', 'value': 1},{'name': '식이섬유', 'value': 1}],
    '5':[{'name': '칼슘', 'value': 1},{'name': '아연', 'value': 1},{'name': '단백질', 'value': 1},{'name': '식이섬유', 'value': 1},{'name': '비타민D', 'value': 1}],
    '6':[{'name': '단백질', 'value': 1},{'name': '식이섬유', 'value': 1},{'name': '비타민D', 'value': 1},{'name': '비타민A', 'value': 1},{'name': '비타민B6', 'value': 1},{'name': '엽산', 'value': 1},{'name': '비타민B12', 'value': 1},{'name': '트립토판', 'value': 1},{'name': '마그네슘', 'value': 1},{'name': 'DHA+EPA', 'value': 1}]};
    const incon_bubble_content = {'7':[],'8':[]};
    const avoid_nutrient = {'1':['지방','고열량','당'],'2':['지방','나트륨'],'3':['포화지방','트랜스지방','콜레스테롤','지방','고열량','당','나트륨'],'4':['포화지방','트랜스지방','콜레스테롤','당'],'5':[],'6':[],'7':['유당'],'8':['고포드맵']}
    return(
        <>
                <UserAfflictionTitle />
                {/* <div className='mb-2' style={{width: '100%', padding:'4px 16px', display:'flex',justifyContent:'center'}}>
                    <Btn onClick={()=>navigate(`/`)}
                        variant="secondary" size="sm" className="lightgray border-no" style={{minWidth: 'auto', borderRadius: '15px', padding: '8px 16px'}} value="장보기 리스트" />  
                </div> */}

                <FlexBox className="column_stretch mt-3 mb-3">
                    <UserAfflictionBox>
                        <ul>
                                            
                            {main_user_detail?.user_incongruity?
                                main_user_detail.user_incongruity.map((item,index, last) => (
                                    <>
                                    {
                                        item.incongruity_id==="7" | item.incongruity_id === "8"?
                                            <li>
                                                <Title className="medium mb-3" value={item.incongruity} /> 
                                                {avoid_nutrient[item.incongruity_id].length != 0?
                                                    <div className="bubblechartwrap mb-2">
                                                        <Title className="semibold mt-3 w_800" value='피하기' /> 
                                                        <Title className="regular" value={`${avoid_nutrient[item.incongruity_id].join(', ')}`} />
                                                    </div>
                                                :null}
                                                <Title className="regular" value={item.incongruity_detail} />
                                            </li>
                                        :null
                                    }
                                    </>
                            )):null}
                            {main_user_detail?.user_affliction?
                                main_user_detail.user_affliction.map((item,index, last) => (
                                    <li key={index}>
                                        <Title className="medium mb-3" value={item.affliction} /> 
                                        {avoid_nutrient[item.affliction_id].length != 0?
                                            <div className="bubblechartwrap mb-2">
                                                <Title className="semibold mt-3 w_800" value='피하기' /> 
                                                <Title className="regular" value={`${avoid_nutrient[item.affliction_id].join(', ')}`} />
                                            </div>
                                        :null}
                                        <Title className="regular" value={item.affliction_detail} />
                                        <div className="bubblechartwrap">
                                            <Title className="semibold mt-3 w_800" value='섭취하기' /> 
                                            <AfflictionBubble json_children={aff_bubble_content[item.affliction_id]} />
                                        </div>
                                    </li>
                                ))
                            :null}
                        </ul>
                    </UserAfflictionBox>
                    <Title className="light mt-3 mb-5 text-left" value='뉴지엄랩은 공인된 기관의 식이 가이드라인과 높은 수준의 최신 과학문헌들을 데이터베이스 및 알고리즘으로 연구개발하여 뉴지엄 마켓을 생성합니다.' /> 
                </FlexBox>
        </>
    )
}