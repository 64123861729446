import { BodyWrapper } from '../../../atom/Wrapper/Wrapper';
import Header,{ LoHeader } from '../../../atom/Header/Header';
import Title from '../../../atom/Title/Title';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../../atom/Wrapper/Wrapper';
import UserList from '../../User/UserList';
import axios from 'axios';
import Footer from '../../../atom/Footer/Footer';
import { useParams } from 'react-router-dom';
import { MypageTitle,MypageDetail } from './Mypage.styled';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch } from 'react-redux';
import { authActions } from '../../../../store/auth-slice';
import { userActions } from '../../../../store/user-slice';
import Accordion from 'react-bootstrap/Accordion';

const Mypage = (props) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleLogout = async() => {
        setIsLoading(true);
        try{
            const response = await axios.delete('/user/auth/', {
                user_id: id
            },
            {headers: {
                "Content-Type": "application/json",}});

            if(response.data.code==='0000'){
                // window.sessionStorage.removeItem('isLoggedIn');
                // window.sessionStorage.removeItem('userId');
                localStorage.clear();
                dispatch(
                    authActions.logout(),
                    userActions.cardclaer()
                )
                
                setIsLoading(false);
                navigate('/');
            }
        }catch(error){
                alert('오류가 발생했습니다. 담당자에게 문의해주세요!');
                setIsLoading(false);
            }
        };

        
    return(
        <BodyWrapper bg="mypagebg">
            <Header value={<LoHeader style={{justifyContent:'space-between'}}><Title className="medium dodum" value="프로필" /></LoHeader>} />
            <Wrapper>
            <MypageTitle>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header> <Title className="bold" value={id} /></Accordion.Header>
                        <Accordion.Body>
                            <MypageDetail>
                                {/* <div>결제/구독</div> */}
                                {/* <div>계정정보 수정</div> */}
                                <div onClick={()=>handleLogout()}>로그아웃</div>
                                <div onClick={()=>navigate(`/mypage/inquiry/${id}`)}>문의 남기기</div>
                                {isLoading ? (
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    ) : (
                                        null
                                    )}
                            </MypageDetail>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </MypageTitle>
                <UserList />
            </Wrapper>
            <Footer />
        </BodyWrapper>
    );
};
export default Mypage;