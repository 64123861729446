import { Link } from "react-router-dom"
import { FlexBox } from "../../../atom/Wrapper/Wrapper";
import { ItemBox,ProductWrap,ProductScroll} from "./Basket.styled";
import Title from '../../../atom/Title/Title';

const BasketRecipe = (props) => {

    return(
        <FlexBox className="column" style={{position: 'relative'}}>
            <ItemBox>
                <li className="basket_recipe">
                    <>
                        <ProductScroll>
                            <ProductWrap>
                                <Link className="preview_wrap">
                                    <img src='https://img-cf.kurly.com/shop/data/goods/165752252140z0.jpg' alt='고사리_제품이미지' />
                                    <Title className="regular mb-3 preview_title" value="레시피1" />
                                    <Title className="thin preview_description" value="레시피재료1,2,3"/>
                                    <span class="material-symbols-outlined" style={{background:'lightsalmon', color:'white'}}>shopping_cart</span>
                                </Link>
                                <Link className="preview_wrap">
                                    <img src='https://img-cf.kurly.com/shop/data/goods/165752252140z0.jpg' alt='고사리_제품이미지' />
                                    <Title className="regular mb-3 preview_title" value="고사리 제품명" />
                                    <Title className="thin preview_description" value="쿠팡" />
                                    <span class="material-symbols-outlined" style={{background:'lightsalmon', color:'white'}}>shopping_cart</span>
                                </Link>
                                <Link className="preview_wrap">
                                    <img src='https://img-cf.kurly.com/shop/data/goods/165752252140z0.jpg' alt='고사리_제품이미지' />
                                    <Title className="regular mb-3 preview_title" value="고사리 제품명" />
                                    <Title className="thin preview_description" value="쿠팡" />
                                    <span class="material-symbols-outlined" style={{background:'lightsalmon', color:'white'}}>shopping_cart</span>
                                </Link>
                            </ProductWrap>
                        </ProductScroll>
                    </>
                </li>
            </ItemBox>
        </FlexBox>
    )
}
export default BasketRecipe;