import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { recordActions } from '../../../store/record-slice';


const RecordCalendarWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    margin-right: 5%;
    @media all and (max-width:1024px){
      width: 100%;
      margin:0;
    }
    .react-calendar{
        font-family: 'Orbit', sans-serif;
        font-family: 'SeoulNamsan','Arial', sans-serif;
        border:none;
        max-width: 800px;
        background: transparent;
        width: 100%;
    }
    .react-calendar__navigation__arrow{
        font-size: 2.5rem;
        font-weight: 600;
        opacity: 0.7;
        font-family: sans-serif;
    }

    //상단 내비게이션(년,월)
    .react-calendar__navigation {
        height: 45px;
        border-radius: 8px;
        transition: .1s all ease;
        margin-bottom: 0;
        span {
          font-size: 1.2rem;
          font-weight: 800;
        }
      }
      .react-calendar__navigation button:disabled {
        background-color: #fff;
        border-radius: 8px 8px 0 0;
      }
      .react-calendar__navigation button:enabled:hover,
      .react-calendar__navigation button:enabled:focus {
        background: rgb(255 255 255 / 70%);
        border-radius: 8px 8px 0 0;
      }

    //월 달력
    .react-calendar__month-view {
    abbr {
        // 텍스트
        font-size: 1rem;
        font-weight: 500;
        color: 	#5F5F5F;
    }
    }
    //요일
    .react-calendar__month-view__weekdays {
        padding-top: 0px;
        padding-bottom: 0px;
        border-bottom: 1px solid #eee;
        border-radius: 8px;
        background: rgb(255 255 255 / 50%);
        abbr {
          // 텍스트 부분
          font-size: 0.9rem;
          font-weight: bolder;
          color: 	#5F5F5F;
          text-decoration: none;
        }
      }

    //일
    .react-calendar__tile {
        text-align: center;
        max-height: 100px;
        min-height: 12.5vh;
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #fff;
        transition: .1s all ease;
        @media all and (max-width:1024px){
          min-height: 60px;
        }
      }
    /*hover, focus, 선택됐을 시 */
    .react-calendar__tile:enabled:hover,
      .react-calendar__tile:enabled:focus,
      .react-calendar__tile--active {
        background: rgb(255 255 255 / 50%);
        border-radius: 4px;
    }
    //현재 날짜
    .react-calendar__tile--now {
        // background: rgba(54, 162, 235, 0.5); //파랑색
        background: rgb(255 196 196); //연핑크색
        border-radius: 4px;
        abbr{
          color: black;
          font-weight: 500;
        }
        span{
          color: black;
          font-weight: 500;
        }
      }
    /*hover, focus 시 */
    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
      background: rgb(255 255 255 / 50%);
      border-radius: 4px;
      abbr{
        color: black;
        font-weight: 500;
      }
      span{
        color: black;
        font-weight: 500;
      }
    }
`

const RecordCalendar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const main_user_id = useSelector((state) => state.user.main_user_id);
  const [date, setDate] = useState(new Date());
  const recordData = useSelector((state) => state.record.monthlyData);

  // 현재 사용자가 보고있는 월 가져오기
  const [currentMonth, setCurrentMonth] = useState(moment(date).format('YYYY-MM'));

  // 달력 날짜 변경에 따라 월 가져오기
  const handleActiveStartDateChange = (date) => {
    const month = (date.activeStartDate.getMonth() + 1).toString().padStart(2, '0'); //한자리 월이라면 0이 붙도록 ex) 03월은 03으로
    const year = date.activeStartDate.getFullYear();
    const current = `${year}-${month}`;
    setCurrentMonth(current);
    props.setCurrentMonth(current);
  };

  const onChange = (newDate) => {
    setDate(newDate);
  };


  // 구매 기록 날짜 리스트
  const [dayList,setDayList] = useState([]);

  // 현재 날짜의 월 사용자기록 불러오기
  const UserRecord = async() => {

    // AbortController를 생성하고 타임아웃을 30초로 설정합니다.
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000);
    let errorCaught = false; // 오류를 캐치했는지 여부를 추적하는 변수

    //사용자 기록읽기(월별) API호출
    try{
        const [year, month] = currentMonth.split('-');
        const response = await axios.post('/record/api/v1/user_record_get_month/', {
            user_card_id: main_user_id,
            year: year,
            month: month
        },
        {headers: {
            "Content-Type": "application/json",},
        signal: controller.signal, // AbortController를 fetch에 전달합니다.
        });
        // 타임아웃 전에 응답이 왔다면 clearTimeout을 호출하여 타임아웃을 취소합니다.
        clearTimeout(timeoutId);

        // 성공적인 응답 처리
        dispatch(
            recordActions.recordget({
                month: currentMonth,
                data: response.data
            })
        );

    }catch(error){
      try{
        if(error.response.status === 400){
            dispatch(
              recordActions.recordget({
                  month: currentMonth,
                  data: []
              })
          );
        }else{
            // 오류를 캐치한 경우, 중복오류 메시지가 뜨지 않도록
            if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
              errorCaught = true; // 오류를 캐치했음을 표시
              alert('사용자 기록 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
            }
        }
      }catch{
        if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
          errorCaught = true; // 오류를 캐치했음을 표시
          alert('사용자 기록 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
        }
      }
    }
  };

  useEffect(()=>{
    // currentmonth가 store에 저장되어 있는지 확인하기 위한 변수
    let hascurrentmonth = false;
    const keys = Object.keys(recordData);
    keys.forEach(key => {
      if(key === currentMonth){
          const array = recordData[key];
          // 배열의 길이가 0보다 큰 경우에만 출력
          if (array.length > 0) {
            // console.log('Key:', key);
            // console.log('Array:', array[0]);
            const dateArray = array[0].map(date => date.record_date);
            setDayList(dateArray)
          }
          hascurrentmonth = true;
      }else{
        // UserRecord();
      };
    });
    if(!hascurrentmonth){
      UserRecord();
    }
  },[recordData,currentMonth]);

  
  const today = new Date();
  
  // 각 날짜 타일에 컨텐츠 추가
  const addContent = ({ date }) => {
    // 해당 날짜(하루)에 추가할 컨텐츠의 배열
    const contents = [];

    //today표기
    if (moment(today).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')){
        contents.push(
            <>
                <p>오늘</p>
            </>
        )
    }

    // date(각 날짜)가  리스트의 날짜와 일치하면 해당 컨텐츠(이모티콘) 추가
    if (dayList.find((day) => day === moment(date).format('YYYY-MM-DD'))) {
      contents.push(
        <>
            <span className="material-symbols-outlined">
            shopping_cart
            </span>
        </>
      );
    }
    return <div>{contents}</div>; // 각 날짜마다 해당 요소가 들어감
  };


  const handleDateClick = (value) => {
    let click_date = moment(value).format('YYYY-MM-DD');
    navigate('/record/'+click_date)
  };

  return (
    <RecordCalendarWrap>
      <Calendar onChange={onChange} value={date}
        onActiveStartDateChange={handleActiveStartDateChange}
        next2Label={null}
        prev2Label={null}
        formatDay={(local, date) => moment(date).format('D')}
        tileContent={addContent}
        showNeighboringMonth={false}
        onClickDay={handleDateClick}
      />
    </RecordCalendarWrap>
  );
};

export default RecordCalendar;
