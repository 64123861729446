import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import {analysisActions} from '../../../store/analysis-slice';
import { BodyWrapper } from '../../atom/Wrapper/Wrapper';
import Header,{ LoHeader } from '../../atom/Header/Header';
import Title from '../../atom/Title/Title';
import Footer from "../../atom/Footer/Footer";
import Wrapper from '../../atom/Wrapper/Wrapper';
import { AnalysisBox, AnalysisContentBox, AnalysisWrap } from './analysis.styled';
import Bubble from './chart/Bubble';
import EatFoodLayout from './chart/EatFoodLayout';
import { useEffect, useState } from 'react';
import OffCanvasExample from './DatePicker';
import { GaugeChart } from './chart/BMI';
import {motion} from 'framer-motion';



const Analysis = () => {
    const dispatch = useDispatch();

    // 스토어에서 user_cards
    const UserCards = useSelector((state)=> state.user.card);
    let UserCardsArray = UserCards?JSON.parse(UserCards):null;
    const main_user_id = useSelector((state) => state.user.main_user_id);
    
    let main_user_data = UserCardsArray?.find(item => item.id === main_user_id);
    let main_user_height = 0;
    let main_user_weight = 0;
    let [now, setNow] = useState(0)
    if(main_user_data){
        main_user_height = main_user_data.user_height / 100;
        main_user_weight = main_user_data.user_weight;
    }

    useEffect(()=>{
        if(main_user_data){
            setNow(Math.floor(main_user_weight / (main_user_height*main_user_height)))
        }
    },[main_user_data,main_user_height,main_user_weight])

    //BMI detail 문구용 변수
    const [BMIColor, SetBMIColor] = useState('success');
    const [BMIDetail, setBMIDetail] = useState('정상입니다');

    // 월&일별 사용자분석 변수
    const check = useSelector((state) => state.analysis.check);

    //타겟 일자, 월
    const targetDate = useSelector((state) => state.analysis.targetdate);
    const targetMonth = useSelector((state) => state.analysis.targetmonth);
    const targetStartDate = useSelector((state) => state.analysis.targetstartdate);
    const targetEndDate = useSelector((state) => state.analysis.targetenddate);
    const targetRangeDate = `${targetStartDate} ~ ${targetEndDate}`;


    const food_category = useSelector((state) => state.basic.food_category);
    const analysisData = useSelector((state) => state.analysis.analysisData);
    console.log('check', check)
    console.log('targetdate : ',targetDate)
    console.log('targetStartDate :',targetStartDate)
    console.log('targetEndDate:', targetEndDate)
    console.log('targetRangeDate :',targetRangeDate)
    console.log(analysisData)

    //영양성분 정보
    const [uniqueNuList, setUniqueNuList] = useState([]);
    const nutritionDetail = {'식이섬유' : '식이섬유는 장내 미생물의 성장을 촉진하고, 변비 예방 및 장 건강을 개선합니다. 혈당 조절을 통해 당뇨병 예방에 기여하고, 포만감을 높여 비만 관리에 도움을 줍니다. 고혈압 및 고콜레스테롤/고중성지방혈증 관리에 유익하며, 건강한 장내 환경을 통해 아이들의 영양 흡수를 개선하여 발달과 키 성장에 긍정적인 영향을 미칩니다. 과민성장증후군 환자의 증상 완화에도 도움이 됩니다.',
    '저열량' : '저열량 식품은 체중 관리와 비만 예방에 유리합니다. 칼로리 섭취를 제한함으로써 체중 감소를 촉진하고, 이는 고혈압, 당뇨병, 고콜레스테롤/고중성지방혈증과 같은 만성 질환의 위험을 감소시킬 수 있습니다. 아이들의 발달과 키 성장에 있어서, 영양소가 풍부하면서 저열량인 식품은 건강한 성장을 지원하며 비만을 예방할 수 있습니다. 저열량 식품은 포만감을 주면서도 적은 에너지를 제공하기 때문에, 전반적인 건강 유지와 만성 질환 관리에 도움을 줄 수 있습니다.',
    '수분' : '수분 함량이 높은 식품은 체내 수분 균형 유지에 중요하며, 이는 소화 촉진, 영양소 흡수 개선, 신진대사 촉진에 기여합니다. 고혈압 및 당뇨병 관리에 있어 수분 섭취는 혈액 순환 개선 및 혈당 조절에 도움을 줄 수 있습니다. 비만 예방에는 포만감을 증가시켜 더 적은 칼로리 섭취로 이끕니다. 또한, 아이들의 발달과 키 성장에 필수적인 영양소의 효율적인 운반과 흡수에 기여합니다. 전반적으로, 수분 함량이 높은 식품은 만성 질환 관리와 건강한 성장에 긍정적인 영향을 미칩니다.',
    '단백질' : '단백질은 세포 구조와 기능에 필수적이며, 근육 성장과 복구, 효소 및 호르몬 생성에 중요합니다. 고혈압, 당뇨병 환자에게는 혈당 조절과 혈압 감소에 도움을 줄 수 있으며, 비만 관리에는 포만감을 증가시켜 체중 감소에 기여합니다. 또한, 고콜레스테롤/고중성지방혈증 관리에 유익한 영향을 미칩니다. 아이들의 발달과 키 성장에 필수적인 역할을 하며, 건강한 신체 발달을 지원합니다.',
    '칼슘' : '칼슘은 뼈의 형성과 유지에 필수적인 영양소로, 아이들의 키 성장에 중요한 역할을 합니다. 뼈의 밀도와 강도를 증가시켜주며, 성장기에 충분한 칼슘 섭취는 최적의 키 성장을 지원합니다. 또한, 근육 기능, 신경 전달, 혈액 응고 과정에도 필요하며, 전반적인 건강과 발달에 기여합니다. 아이들에게 충분한 칼슘을 제공하는 것은 건강한 성장과 발달을 위해 매우 중요합니다.',
    '마그네슘' : '마그네슘은 300개 이상의 효소 시스템의 활성화에 필수적이며, 에너지 생산, 단백질 합성, 근육 및 신경 기능 조절에 중요한 역할을 합니다. 아이들의 발달에 있어서 마그네슘은 뼈의 성장과 강도를 증진시키는 데 기여하며, 정상적인 신경 전달과 근육 수축을 도와주어 건강한 발달을 지원합니다. 또한, 마그네슘은 아이들의 면역 체계 강화와 전반적인 건강 유지에도 도움을 줍니다.',
    '아연' : '아연은 세포 성장, 분화, DNA 합성에 필수적이며, 면역 기능 강화와 상처 치유를 돕습니다. 아이들의 발달에 있어서 아연은 성장 정체를 예방하고, 학습 능력 및 면역 체계를 강화하는 데 중요한 역할을 합니다. 아연 결핍은 발달 지연과 관련이 있어 아이들의 식단에 충분한 아연이 포함되어야 합니다.',
    '비타민A' : '비타민A는 시력 유지, 면역 체계 강화, 세포 성장과 분화에 중요한 역할을 합니다. 아이들의 발달에 있어서는 건강한 피부, 눈, 면역 체계의 유지와 성장 발달을 지원합니다. 비타민A 결핍은 시력 문제와 면역력 약화로 이어질 수 있어, 아이들의 균형 잡힌 식단에는 비타민A가 풍부한 식품이 포함되어야 합니다.',
    '비타민D':'비타민D는 칼슘과 인의 흡수를 촉진하여 뼈와 치아를 강화하는 데 필수적입니다. 아이들의 경우, 비타민D는 정상적인 성장과 발달을 지원하며, 뼈의 형성과 강화에 중요한 역할을 합니다. 비타민D 결핍은 아동기 뼈의 약화나 변형을 초래할 수 있는 구루병의 위험을 증가시킬 수 있습니다. 따라서, 아이들의 건강한 발달과 키 성장을 위해 충분한 비타민D 섭취가 중요합니다.', 
    '비타민B6' : '비타민B6는 단백질 대사, 뇌 기능 및 면역 체계 강화에 중요합니다. 아이들에게는 성장, 신경 발달, 정서 조절에 필수적이며, 결핍 시 발달 지연이나 면역력 약화가 발생할 수 있습니다. 따라서, 비타민B6는 아이들의 건강한 발달과 성장을 지원하는 데 필요한 영양소입니다.',
    '비타민B12':'비타민B12는 DNA 합성, 적혈구 형성, 신경계 기능 유지에 중요합니다. 아이들의 발달에 있어서 비타민B12는 뇌 발달과 인지 기능을 지원하며, 결핍은 발달 지연이나 학습 장애로 이어질 수 있습니다. 따라서, 비타민B12는 아이들의 성장과 건강한 신경계 발달에 필수적인 영양소입니다.',
    '엽산' : '엽산은 DNA 합성 및 세포 분열에 필수적이며, 아이들의 성장과 발달에 중요한 역할을 합니다. 특히, 임신 초기에 엽산 섭취는 신경관 결손의 위험을 줄이는 데 도움이 됩니다. 아이들에게 충분한 엽산은 적혈구 형성을 지원하고, 성장기 동안 건강한 세포 발달을 촉진합니다.', 
    '트립토판':'트립토판은 필수 아미노산으로, 세로토닌과 멜라토닌의 전구체입니다. 이러한 신경전달물질은 기분 조절과 수면 주기에 중요하며, 아이들의 정서 안정과 건강한 수면 패턴 형성에 기여합니다. 트립토판은 또한 아이들의 발달과 성장에 필수적인 역할을 하며, 충분한 섭취는 아이들의 웰빙과 전반적인 발달에 긍정적인 영향을 미칩니다.',
    'DHA+EPA':'DHA와 EPA는 오메가-3 지방산으로, 뇌 기능과 시력 발달에 중요합니다. 이들은 아이들의 인지 발달과 학습 능력 향상에 기여하며, 정서 조절과 주의력 개선에도 도움을 줍니다. 또한, 면역 체계 강화와 염증 반응 감소에도 영향을 미칩니다.',
    '유당':'유당은 우유 및 유제품에 있는 자연당으로, 체내에서 에너지원으로 사용됩니다. 유당은 장내 유익한 박테리아의 성장을 돕고, 칼슘 흡수를 촉진하여 뼈 건강에 기여할 수 있습니다. 유당불내증이 있는 사람들은 유당을 분해하는 데 필요한 효소인 락타아제가 부족하거나 없어 유당을 소화시키지 못합니다. 유당불내증 관리에는 유당이 적은 식품 선택, 락타아제 보충제 섭취, 유당 분해가 가능한 유제품 대체품 사용이 포함될 수 있습니다.',
    '고포드맵':'고포드맵(FODMAP; fermentable oligosaccharides, disaccharides, monosaccharides and polyols) 식품은 소화가 어려운 단당류, 이당류, 다당류, 알코올당을 포함합니다. 이러한 성분은 과민성장증후군 환자에서 가스, 복통, 설사 또는 변비 같은 증상을 유발할 수 있습니다. 고포드맵 식단 관리는 이러한 식품을 제한하여 소화기 증상을 줄이고 장 건강을 개선하는 데 도움을 줍니다. 올바른 식단 조절을 통해 과민성장증후군의 관리와 삶의 질 향상에 기여할 수 있습니다.',
    '고열량' : '고열량 식품의 과도한 섭취는 체중 증가와 비만을 유발할 수 있으며, 이는 고혈압, 당뇨병, 고콜레스테롤/고중성지방혈증 등 다양한 건강 문제의 위험을 증가시킵니다. 아이들의 경우, 균형 잡힌 영양 섭취가 중요한데, 고열량 식품의 과다 섭취는 아이들의 건강한 발달과 키 성장에 부정적인 영향을 미칠 수 있습니다. 건강한 성장과 발달, 만성 질환 예방을 위해서는 영양소가 풍부하고 열량이 적절한 식품을 선택하는 것이 중요합니다.',
    '지방' : '지방 함량이 높은 식품의 과다 섭취는 체중 증가, 비만, 고혈압, 당뇨병, 고콜레스테롤/고중성지방혈증과 같은 건강 문제의 위험을 높일 수 있습니다. 그러나, 건강한 지방(예: 오메가-3 지방산)은 아이들의 뇌 발달과 키 성장에 긍정적인 영향을 줄 수 있습니다. 따라서 지방 섭취는 건강한 지방을 선택하고 적정량을 유지하는 것이 중요합니다.',
    '포화지방':'포화지방 함량이 높은 식품의 과다 섭취는 심혈관 질환 위험을 증가시키며, 고혈압, 당뇨, 비만, 고콜레스테롤/고중성지방혈증과 같은 건강 문제와 연관이 있습니다. 아이들의 경우, 적절한 영양 섭취가 중요하며, 과도한 포화지방 섭취는 건강한 발달과 키 성장에 부정적 영향을 줄 수 있습니다. 따라서 균형 잡힌 식단을 통해 다양한 영양소를 적절히 섭취하는 것이 중요합니다.',
    '트랜스지방':'트랜스지방은 혈중 나쁜 콜레스테롤(HDL; High Density Lipoproteins)을 증가시키고 좋은 콜레스테롤(LDL; Low Density Lipoproteins)을 감소시켜 심혈관 질환 위험을 높입니다. 고혈압, 당뇨, 비만, 고콜레스테롤/고중성지방혈증 등 만성 질환의 위험도 증가시킬 수 있습니다. 아이들의 건강한 발달과 키 성장에도 부정적 영향을 미칠 수 있어, 트랜스지방 섭취는 가능한 한 제한하는 것이 중요합니다.',
    '콜레스테롤':'콜레스테롤 함량이 높은 식품의 과도한 섭취는 혈중 LDL 콜레스테롤 수치를 높여 심혈관 질환의 위험을 증가시킵니다. 이는 고혈압, 당뇨, 비만, 고콜레스테롤/고중성지방혈증과 같은 만성 질환의 위험을 높일 수 있습니다. 아이들의 경우, 건강한 식습관을 형성하는 데 있어 적절한 콜레스테롤 섭취가 중요하며, 이는 건강한 발달과 키 성장을 지원합니다. 따라서 균형 잡힌 식단을 통해 콜레스테롤 섭취를 관리하는 것이 중요합니다.',
    '당':'당 함량이 높은 식품의 과다 섭취는 혈당 수치를 급격히 상승시켜 당뇨병의 위험을 증가시킵니다. 또한, 비만, 고혈압, 고콜레스테롤/고중성지방혈증과 같은 만성 질환의 위험도 높일 수 있습니다. 아이들의 경우, 과도한 설탕 섭취는 비만을 유발하고, 건강한 발달과 키 성장에 부정적인 영향을 줄 수 있습니다. 따라서, 건강한 식습관 형성과 만성 질환 예방을 위해 당 함량이 높은 식품의 섭취를 제한하는 것이 중요합니다.',
    '나트륨':'나트륨 함량이 높은 식품의 과다 섭취는 체내 수분 보유를 증가시켜 고혈압의 위험을 높일 수 있습니다. 이는 당뇨병, 비만, 고콜레스테롤/고중성지방혈증과 같은 만성 질환의 발병 위험도 증가시킵니다. 아이들의 발달과 키 성장에 있어서도, 과도한 나트륨 섭취는 부정적인 영향을 미칠 수 있으며, 건강한 성장을 위해 나트륨 섭취를 제한하는 것이 중요합니다.'
};

    // 사용자 분석 특정 날짜 데이터 불러오기 API호출
    const UserAnalysisDay = async() => {
        // AbortController를 생성하고 타임아웃을 30초로 설정합니다.
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 30000);
        let errorCaught = false; // 오류를 캐치했는지 여부를 추적하는 변수

        try{
            const response = await axios.post('/record/api/v1/user_day_analysis_get/', {
                user_card_id: main_user_id,
                record_date: targetDate
            },
            {headers: {
                "Content-Type": "application/json",},
            signal: controller.signal, // AbortController를 fetch에 전달합니다.
            });
            // 타임아웃 전에 응답이 왔다면 clearTimeout을 호출하여 타임아웃을 취소합니다.
            clearTimeout(timeoutId);
            
            // 성공적인 응답 처리
            dispatch(
                analysisActions.analysisget({
                    month: targetDate,
                    data: response.data
                })
            );

        }catch(error){
            try{
                if(error.response.status === 400){
                    if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                        errorCaught = true; // 오류를 캐치했음을 표시
                        alert('기록이 없습니다. 구매기록을 남겨주세요.');
                        // 성공적인 응답 처리
                        dispatch(
                            analysisActions.analysisget({
                                month: targetDate,
                                data: error.response.data.message
                            })
                        );
                    }
                }else{
                    // 오류를 캐치한 경우, 중복오류 메시지가 뜨지 않도록
                    if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                        errorCaught = true; // 오류를 캐치했음을 표시
                        alert('사용자 분석 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                    }
                }
            }catch{
                // 오류를 캐치한 경우, 중복오류 메시지가 뜨지 않도록
                if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                    errorCaught = true; // 오류를 캐치했음을 표시
                    alert('사용자 분석 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                }   
            };

        };
    };

    // 사용자 분석 (기간설정) 데이터 불러오기 API호출
    const UserAnalysisPeriod = async() => {
        // AbortController를 생성하고 타임아웃을 30초로 설정합니다.
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 30000);
        let errorCaught = false; // 오류를 캐치했는지 여부를 추적하는 변수
        let dateRangeString = `${targetStartDate} ~ ${targetEndDate}`;

            try{
                const response = await axios.post('/record/api/v1/user_analysis_get/', {
                    user_card_id: main_user_id,
                    start_date: targetStartDate,
                    end_date: targetEndDate
                },
                {headers: {
                    "Content-Type": "application/json",},
                signal: controller.signal, // AbortController를 fetch에 전달합니다.
                });
                // 타임아웃 전에 응답이 왔다면 clearTimeout을 호출하여 타임아웃을 취소합니다.
                clearTimeout(timeoutId);
                
                // 성공적인 응답 처리
                dispatch(
                    analysisActions.analysisget({
                        month: dateRangeString,
                        data: response.data
                    })
                );
    
            }catch(error){
                try{
                    if(error.response.status === 400){
                        if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                            errorCaught = true; // 오류를 캐치했음을 표시
                            alert('기록이 없습니다. 구매기록을 남겨주세요.');
                            // 성공적인 응답 처리
                            dispatch(
                                analysisActions.analysisget({
                                    month: dateRangeString,
                                    data: error.response.data.message
                                })
                            );
                        }
                    }else{
                        // 오류를 캐치한 경우, 중복오류 메시지가 뜨지 않도록
                        if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                            errorCaught = true; // 오류를 캐치했음을 표시
                            alert('사용자 분석 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                        }
                    }
                }catch{
                    // 오류를 캐치한 경우, 중복오류 메시지가 뜨지 않도록
                    if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                        errorCaught = true; // 오류를 캐치했음을 표시
                        alert('사용자 분석 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                    }   
                };
    
            };
        };

    // 사용자 분석 특정 날짜 데이터 불러오기 API호출
    const UserAnalysisMonth = async() => {
    // AbortController를 생성하고 타임아웃을 30초로 설정합니다.
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000);
    let errorCaught = false; // 오류를 캐치했는지 여부를 추적하는 변수
    let parts = targetMonth.split('-');

        try{
            const response = await axios.post('/record/api/v1/user_month_analysis_get/', {
                user_card_id: main_user_id,
                year: parseInt(parts[0]),
                month: parseInt(parts[1])
            },
            {headers: {
                "Content-Type": "application/json",},
            signal: controller.signal, // AbortController를 fetch에 전달합니다.
            });
            // 타임아웃 전에 응답이 왔다면 clearTimeout을 호출하여 타임아웃을 취소합니다.
            clearTimeout(timeoutId);
            
            // 성공적인 응답 처리
            dispatch(
                analysisActions.analysisget({
                    month: targetMonth,
                    data: response.data
                })
            );

        }catch(error){
            try{
                if(error.response.status === 400){
                    if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                        errorCaught = true; // 오류를 캐치했음을 표시
                        alert('기록이 없습니다. 구매기록을 남겨주세요.');
                        // 성공적인 응답 처리
                        dispatch(
                            analysisActions.analysisget({
                                month: targetMonth,
                                data: error.response.data.message
                            })
                        );
                    }
                }else{
                    // 오류를 캐치한 경우, 중복오류 메시지가 뜨지 않도록
                    if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                        errorCaught = true; // 오류를 캐치했음을 표시
                        alert('사용자 분석 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                    }
                }
            }catch{
                // 오류를 캐치한 경우, 중복오류 메시지가 뜨지 않도록
                if (!errorCaught) { // 오류를 캐치한 적이 없는 경우에만 동작을 수행
                    errorCaught = true; // 오류를 캐치했음을 표시
                    alert('사용자 분석 데이터 로드 중 오류가 발생했습니다. 담당자에게 문의해주세요!');
                }   
            };

        };
    };

    // BMI 계산
    useEffect(()=>{
        if(now < 18.5){
            SetBMIColor('info');
            setBMIDetail('저체중입니다.');
        }else if(18.5 <= now < 23){
            SetBMIColor('success');
            setBMIDetail('정상입니다.');
        }else if(23<= now < 25){
            SetBMIColor('warning');
            setBMIDetail('과체중입니다.');
        }else{
            SetBMIColor('danger');
            setBMIDetail('비만입니다.');
        }
    },[now])

    useEffect(()=>{
        if(main_user_id !== 0){
            if(check == 'today' || check == 'day'){
                try{
                    if(analysisData[targetDate].length == 0){
                        if(analysisData[targetDate][0] === 'No records found for the given user card ID and date'){
        
                        }else{
                            UserAnalysisDay();
                        }            
                    }
                }catch{
                    UserAnalysisDay();
                }
            }else{
                if (targetEndDate < targetStartDate) {
                    console.log('기간설정에 오류가 있습니다.')
                }else{
                    if(analysisData[targetRangeDate]){
                        try{
                            if(analysisData[targetRangeDate].length == 0){
                                if(analysisData[targetRangeDate][0] === 'No records found for the given period'){
                
                                }else{
                                    UserAnalysisPeriod();
                                }
                            }else{
                                if(analysisData[targetRangeDate].length == 1){
                                try{
                                    if(analysisData[targetRangeDate][0] === 'User Record Not Exists'){
                                    }}catch{}
                                }
                            }
                        }catch{
                            UserAnalysisPeriod();
                        }
                    }else{
                        UserAnalysisPeriod();
                    }
                }
            }
        }
    },[targetDate, check, targetRangeDate, main_user_id])

    //framer moition 애니메이션 추가
    const motionwrap = {
        visible: {
          transition: {
            staggerChildren: 0.03
          }
        }
    }
    const motionbox = {
        hidden: {opacity:0.5, scale:0.9},
        visible: {opacity:1, scale: 1}
    }
    const motiontext={
        hidden: {opacity:0, scale:0.9},
        visible: {opacity:1, scale: 1}
    }
    return(
        <BodyWrapper bg="analysisbg">
            <Header value={
            <LoHeader style={{justifyContent:'space-between'}}>
                <Title className="logo" value="구매 분석" />
            </LoHeader>} />
            <Wrapper box={{padding:0, position:'relative'}}>
                {/* <Title className="medium pt-4 text-left" style={{borderBottom: '1px solid #3c3c3c'}} value={check==='day' || check === 'today' ? targetDate:targetMonth} /> */}
                <OffCanvasExample placement='top' />

                <AnalysisWrap
                    variants={motionwrap}
                    initial={"hidden"}
                    animate={"visible"}>
                    <AnalysisBox className='full mb-3' variants={motionbox}>
                        <AnalysisContentBox>
                            {/* <Title className="bold m-2 mb-4" value="BMI" /> */}
                            <div className='flex'>
                                <div style={{maxWidth:'500px',margin:'0 auto'}}>
                                    {now==0?
                                    null:
                                    <GaugeChart value={now} />}
                                </div>
                                <div className='bmi_title'>
                                    <Title className="medium w_800 mb-3" value= {`BMI지수 : ${now}`} />
                                    <Title className="regular mb-2" value={BMIDetail} />
                                    <Title className="thin mt-3 pt-3" style={{borderTop:'1px solid #eee'}}
                                        value="체질량지수는 자신의 몸무게(kg)를 키의 제곱(m)으로 나눈 값입니다." />
                                </div>
                            </div>
                        </AnalysisContentBox>
                    </AnalysisBox>
                    {check ==='day'|| check==='today' && analysisData[targetDate]&&analysisData[targetDate][0] === 'No records found for the given user card ID and date'
                        || check === 'period' && analysisData[targetRangeDate]&&analysisData[targetRangeDate][0] === 'User Record Not Exists'?null 
                    :
                    <>
                    <AnalysisBox className='mb-3' variants={motionbox}>
                        <AnalysisContentBox style={{position:'relative', minHeight:'400px'}}>
                            <Title className="bold m-2 mb-4" value="골고루 지수" />
                            <EatFoodLayout targetDate={check==='day' || check === 'today' ?targetDate:targetRangeDate} analysisData={analysisData} food_category={food_category} />
                        </AnalysisContentBox>
                    </AnalysisBox>
                    <AnalysisBox className='mb-3' variants={motionbox}>
                        <AnalysisContentBox>
                            <Title className="bold m-2 mb-4" value="구매한 성분내용" />
                            <Bubble check={check} targetDate={check==='day' || check === 'today' ?targetDate:targetRangeDate} analysisData={analysisData} setUniqueNuList={setUniqueNuList}  />
                        </AnalysisContentBox>
                    </AnalysisBox>
                    <AnalysisBox className='full mb-5' variants={motionbox}>
                            <AnalysisContentBox className='gray_border mb-5' style={{boxShadow:'none'}}>
                                <Title className="bold  m-2 mb-4" value="영양 정보" />
                                <motion.ul
                                    variants={{
                                        visible:{transition:{staggerChildren: 0.2}}}}
                                    initial={"hidden"}
                                    animate={"visible"}>
                                    {uniqueNuList?.map((nutrition,index) => (

                                        <motion.li className='mr-2 ml-2' variants={motiontext}>
                                            {nutritionDetail[nutrition]?
                                                <>
                                                <Title className="semibold text-left" value={nutrition} />
                                                    <p className='mb-4'>
                                                        <Title className="light text-left" value={nutritionDetail[nutrition]} />
                                                    </p>
                                                </>
                                            :null}
                                        </motion.li>
                                    ))}
                                </motion.ul>
                            </AnalysisContentBox>
                    </AnalysisBox>
                    </>
                    }
                </AnalysisWrap>
                </Wrapper>
                <Footer />
        </BodyWrapper>
    )
}
export default Analysis;