import styled from "styled-components";

export const LoginBox = styled.div`
    padding: 16px 0;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    width: 500px;
    max-width: 100%;
    margin: 32px auto;
    .logo_img{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    img{
        width: 120px;
        margin: 16px auto 0;
    }
    .big_logo{
        margin: 16px auto 48px;
    }
    @media all and (max-width:600px){
        border: none;
    }
`

export const FormBox = styled.div`
    margin: 1rem auto;
    padding: 0 1rem;
    max-width: 480px;
    input{
        border:none;
        border-bottom: 1px solid #dee2e6;
        border-radius: 0;
    }
    @media all and (min-width:600px){
        width: 500px;
    }
    .row-flex{
        display: flex;
        align-items: center;
    }
    .column-flex{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .space-between{
        justify-content: space-between;
        align-items: flex-start;
    }
    .none{
        opacity: 0;
    }
    .label_font{
        font-size: 0.9rem;
    }
    .strong{
        font-family: 'SeoulNamsan','Arial', sans-serif;
        font-weight: 600;
        margin-top: 1rem;
    }
    .raio_btn input{
        border: 1px solid #dee2e6;
        border-radius: 0.25rem;
    }
    .gender_checked{
        background: rgba(255, 206, 86, 0.5);
    }
    .sm_width{
        max-width: 100px;
    }
`

export const ErrBox = styled.div`
    margin-bottom: 1rem;
    padding-left: calc(20px + 1rem);
    color: red;
`

export const TimerBox = styled.div`
    color: blue;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-align: right;
`
export const LabelBox = styled.span`
    text-decoration: 1px solid underline gray;
    color: darkgray;
    font-size: 0.7rem;
    cursor: pointer;
`
export const ViewLabel = styled.div`
    margin: 0 1rem; 
    padding: 0.25rem 1rem;
    border: 1px solid lightgray;
    border-radius: 5px;
    white-space: pre-line;
    height: 150px;
    overflow-y: scroll;
`


