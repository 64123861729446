import Header,{ LoHeader } from "../../../atom/Header/Header";
import Wrapper, { BodyWrapper } from "../../../atom/Wrapper/Wrapper";
import Title from "../../../atom/Title/Title";
import styled from "styled-components";
import Btn from "../../../atom/Btn/Btn";
import KakaoLogin from "../../Register/SNSLogin";
import { useNavigate } from "react-router-dom";


const SubscriptionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

`
const SubscriptionBox = styled.div`
    padding: 1rem;
    margin: 10px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    width: 50%;
    white-space: pre-line;
`


const Subscription = (props) => {
    const navigate = useNavigate();
    return(
        <BodyWrapper>
            <Header value={<LoHeader style={{justifyContent:'space-between'}}><Title className="medium" value="구독/결제" /></LoHeader>} />
            <Wrapper>
            <Title className="medium mt-4" value={`뉴지엄 마켓\n개인맞춤영양 장바구니 플랫폼 이용권`} />
            <Title className="regular mt-3" value={'간단한 건강고민설문\n개인건강에 맞추어진 장바구니 구성\ne-commerce와 연결\n구매내역의 다양성 분석\n구매내역의 영양성분 분석\n구매내역의 기능성 정보 전달'} />
                <SubscriptionWrapper className="mt-3">
                    <SubscriptionBox>
                        <Title className="semibold" value={'월 정기구독'} />
                        <Title className="regular" value={'9,900원 / 월'} />
                        <Title className="light" value={'정기구독'} />
                        <Btn onClick={()=>navigate('/subscription/pay/basic')} variant="primary" size="lg" type="submit" className="full pinegreen mt-4 mb-3" value="신청" />
                    </SubscriptionBox>
                    <SubscriptionBox>
                        <Title className="semibold" value={'3개월 정기구독'} />
                        <Title className="regular" value={'25,000원 / 3개월'} />
                        <Title className="light" value={'16%할인'} />
                        <Btn onClick={()=>navigate('/subscription/pay/premium')}variant="primary" size="lg" type="submit" className="full lightsalmon mt-4 mb-3" value="신청" />
                    </SubscriptionBox>
                </SubscriptionWrapper>
            </Wrapper>
        </BodyWrapper>
    )
}
export default Subscription;