import { BodyWrapper } from '../../atom/Wrapper/Wrapper';
import Header,{ LoHeader } from '../../atom/Header/Header';
import Wrapper, { FlexBox } from "../../atom/Wrapper/Wrapper";
import Title from '../../atom/Title/Title';
import Footer from "../../atom/Footer/Footer";
import { useNavigate } from "react-router-dom"
import { AboutSection, AboutLogoBox, AboutNews, AboutTitle, NewsBox, NewsList, NewsListTitle, NewsListWrap, GuidelineBox } from './About.styled';
import Btn from '../../atom/Btn/Btn';
import Accordion from 'react-bootstrap/Accordion';
import { UserAfflictionDetail } from '../User/UserAfflictionDetail';

const About = () => {
    const navigate = useNavigate();
    const guideline = {'당뇨':'당뇨 관리를 위한 식이 가이드라인은 균형 잡힌 식단 구성, 탄수화물 섭취에 주의, 섬유질이 풍부한 식품 섭취 증가, 건강한 지방 선택, 당분 및 포화 지방이 많은 음식의 섭취 제한 등을 포함합니다. \n또한, 규칙적인 식사와 간식을 통해 혈당 수준을 안정시키고, 알코올 섭취를 제한하며, 음식의 혈당 지수를 고려하는 것이 좋습니다.'
    ,'유당불내증(유당분해효소결핍증)':'유당불내증 관리를 위한 식이 가이드라인은 유당이 포함된 음식의 섭취를 제한하고 유당이 적은 대체 식품을 선택하는 것을 포함합니다. 유당이 적은 우유나 유제품, 유당분해효소가 첨가된 제품을 선택하는 것이 도움이 될 수 있습니다. \n또한, 식사 시 유당 함량을 점차적으로 증가시켜 본인의 유당 내성 수준을 파악하는 것도 중요합니다.',
    '과민성장증후군':'과민성장증후군 관리를 위한 식이 가이드라인으로는 저포드맵 식단을 권장하며, 고지방 식품, 카페인, 알코올, 탄산음료의 섭취를 줄이는 것이 좋습니다. 규칙적인 식사 시간을 유지하고, 충분한 수분 섭취와 함께 섬유질 섭취를 조절하여 증상을 완화할 수 있습니다. ',
    '고콜레스테롤/고중성지방혈증':'고콜레스테롤/고중성지방혈증 관리를 위한 식이 가이드라인에는 포화지방과 트랜스지방 섭취를 줄이고, 식물성 지방과 오메가-3 지방산이 풍부한 식품을 섭취하는 것이 포함됩니다. 섬유질이 많은 곡물, 과일, 채소의 섭취를 늘리고, 정제된 탄수화물과 설탕이 많은 음식은 제한해야 합니다.',
    '신경계 발달':'아이들의 신경행동발달을 위한 식이 가이드라인은 DHA, EPA, 엽산, 마그네슘, 트립토판, 비타민 A, B6, B12, D, 식이섬유 등 다양한 영양소의 충분한 섭취를 강조합니다. 이러한 영양소는 아동의 뇌 기능과 관련된 다양한 신경계의 발달에 도움이 될 수 있습니다. 영양소별로 충분한 섭취를 위한 식품을 선택하며, 편식을 방지하고 균형 잡힌 식단을 유지하는 것이 중요합니다. \n또한, 아동의 영양 상태와 식사 습관을 주기적으로 모니터링하여 필요시 전문가와 상담하는 것이 권장됩니다.',
    '키성장':'아이들의 키 성장을 위한 식이 가이드라인으로는 균형 잡힌 영양 섭취가 중요합니다. 단백질, 칼슘, 철분, 비타민 D 등이 풍부한 식품을 적절히 섭취하는 것이 좋습니다. 단백질은 성장과 세포 재생에 필수적이며, 칼슘과 비타민 D는 뼈의 성장과 강화에 중요합니다. 철분은 혈액의 산소 운반 능력을 향상시켜 전반적인 성장을 돕습니다. 건강한 식습관을 위해 가공식품과 설탕이 많은 음식의 섭취를 제한하고, 신선한 과일, 채소, 통곡물, 저지방 유제품, 고기, 생선 등을 포함한 다양한 식품을 섭취하는 것이 중요합니다. 충분한 수분 섭취도 잊지 말아야 합니다.',
    '비만':'체중 감량을 위한 식이 가이드라인은 균형 잡힌 영양 섭취, 정기적인 식사, 과도한 칼로리 섭취 제한을 포함합니다. 주로 채소, 과일, 통곡물, 단백질(저지방 육류, 생, 콩류)을 섭취하고, 가공식품, 고지방, 고당분 음식은 제한해야 합니다. \n또한, 충분한 수분 섭취와 함께 규칙적인 식사 시간을 유지하는 것이 중요합니다. 열량 섭취를 줄이면서도 필요한 영양소는 충분히 섭취해야 하므로, 식사 계획에 주의를 기울여야 합니다.',
    '식품 알레르기':'식품 알레르기 관리를 위한 식이 가이드라인은 알레르기를 유발하는 식품을 파악하고 피하는 것으로 시작합니다. 의심되는 알레르기 유발 식품을 제거한 후 점차 다시 도입하여 구체적인 원인을 찾는 제거 식단을 시도할 수 있습니다. 식품 라벨을 주의 깊게 읽어 숨겨진 알레르기 유발 물질을 피하고, 교차 오염의 위험을 인지하는 것이 중요합니다.',
    '고혈압':''};
    
    const guidelinelink ={'당뇨':{'질병관리청 국가건강정보포털 - 당뇨환자의 식사요법':'https://health.kdca.go.kr/healthinfo/biz/health/gnrlzHealthInfo/gnrlzHealthInfo/gnrlzHealthInfoView.do?cntnts_sn=3388','대한당뇨병학회':'https://www.diabetes.or.kr/general/dietary/dietary_01.php',
    '서울아산병원 - 당뇨병식':'https://www.amc.seoul.kr/asan/mobile/healthinfo/mealtherapy/mealTherapyDetail.do?mtId=46','삼성서울병원 식사요법 - 당뇨교육':'http://www.samsunghospital.com/dept/main/index.do?DP_CODE=DM&MENU_ID=008034','삼성서울병원 당뇨병 - 식사요법':'http://www.samsunghospital.com/dept/medical/dietarySub01.do?content_id=623&DP_CODE=DD2&MENU_ID=002&ds_code=D0000815'}
    ,'유당불내증(유당분해효소결핍증)':{'서울아산병원 - 유당분해효소결핍증':'https://www.amc.seoul.kr/asan/healthinfo/disease/diseaseDetail.do?contentId=32090','삼성서울병원 - 유당불내증':'http://www.samsunghospital.com/home/healthInfo/content/contenView.do?CONT_SRC_ID=09a4727a8000f2f8&CONT_SRC=CMS&CONT_ID=758&CONT_CLS_CD=001020001008',
    '세브란스 건강정보 - 유당불내증':'https://medicine.yonsei.ac.kr/health/encyclopedia/disease/body_board.do?mode=view&articleNo=66618&title=%EC%9C%A0%EB%8B%B9%EB%B6%88%EB%82%B4%EC%A6%9D+%5BLactose+intolerance%5D#:~:text=%EC%9A%B0%EC%9C%A0%EB%A5%BC%20%EB%A7%88%EC%8B%9C%EA%B3%A0%20%EB%82%98%EB%A9%B4%20%EB%B0%B0%EA%B0%80,%EC%9D%B4%EA%B2%83%EC%9D%84%20%EC%9C%A0%EB%8B%B9%EB%B6%88%EB%82%B4%EC%A6%9D%EC%9D%B4%EB%9D%BC%EA%B3%A0%20%ED%95%B4%EC%9A%94.'}
    ,'과민성장증후군':{'질병관리청 국가건강정보포털 - 과민성장증후군':'https://health.kdca.go.kr/healthinfo/biz/health/gnrlzHealthInfo/gnrlzHealthInfo/gnrlzHealthInfoView.do?cntnts_sn=5250','대한소화기기능성질환·운동학회 - 과민성 장증후군과 음식':'https://www.ksgm.org/down/lecture_12_04_02.pdf',
    '삼성서울병원 - [질환과 맞춤 영양소] 저 포드맵 식사, 민감한 나의 장을 부탁해':'http://www.samsunghospital.com/home/healthInfo/content/contenView.do?CONT_SRC_ID=32421&CONT_SRC=HOMEPAGE&CONT_ID=4188&CONT_CLS_CD=001021003005'}
    ,'고콜레스테롤/고중성지방혈증':{'서울대학교병원 건강칼럼 - 콜레스테롤 수치를 낮추는 음식과 식습관은 무엇인가요':'https://terms.naver.com/entry.naver?docId=2103376&cid=63166&categoryId=51020','서울아산병원 고지혈증식':'https://www.amc.seoul.kr/asan/mobile/healthinfo/mealtherapy/mealTherapyDetail.do?mtId=27',
    '서울특별시 식생활종합지원센터 고지혈증 식사요법':'https://www.seoulnutri.co.kr/foodinfo_sub/11.do'}
    ,'신경계 발달':{'질병관리청 국가건강정보포털 - 체중이 늘지 않아요. (성장부진, 성장장애)':'https://health.kdca.go.kr/healthinfo/biz/health/ntcnInfo/healthSourc/healthColmn/healthColmnView.do?health_colmn_sn=1821','질병관리청 국가건강정보포털 - 정상소아의 성장(발달)':'https://health.kdca.go.kr/healthinfo/biz/health/gnrlzHealthInfo/gnrlzHealthInfo/gnrlzHealthInfoView.do?cntnts_sn=5369',
    '질병관리청 국가건강정보포털 - 정상청소년의 성장과 발달':'https://health.kdca.go.kr/healthinfo/biz/health/gnrlzHealthInfo/gnrlzHealthInfo/gnrlzHealthInfoView.do?cntnts_sn=5416','삼성서울병원 - [쑥쑥 크는 10대 영양] 신나는 초등, 건강 프로젝트!':'http://www.samsunghospital.com/home/healthInfo/content/contenView.do?CONT_SRC_ID=28560&CONT_SRC=HOMEPAGE&CONT_ID=4285&CONT_CLS_CD=001021008002',
    '삼성서울병원 - [쑥쑥 크는 10대 영양] 청소년을 위한 영양 챙기기':'http://www.samsunghospital.com/home/healthInfo/content/contenView.do?CONT_SRC_ID=27964&CONT_SRC=HOMEPAGE&CONT_ID=3663&CONT_CLS_CD=001021008002','삼성서울병원 - [쑥쑥 크는 10대 영양] 파릇파릇한 봄, 제철음식을 이용한 청소년의 건강 식단과 간식 요리법':'http://www.samsunghospital.com/home/healthInfo/content/contenView.do?CONT_SRC_ID=28510&CONT_SRC=HOMEPAGE&CONT_ID=4283&CONT_CLS_CD=001021008002'}
    ,'키성장':{'서울대학교 의과대학 국민건강지식센터. 아이 키, 언제 얼마나 자라나':'https://hqcenter.snu.ac.kr/archives/2759','서울대학교병원. 아이 키 성장과 그에 관한 속설':'https://youtu.be/zDJJ_Q1MP5g?si=l1Tm6OHYBvc6g81Q','강남세브란스병원. 청소년기 키 크는 가장 확실한 방법':'https://youtu.be/_xLZ1_Dp8K4?si=hKW5Kr5pu4slPQXU',
    '서울아산병원. 키 성장의 골든타임, 성장판 [건강플러스]':'https://www.youtube.com/watch?v=y6oYJ3kHX-k'}
    ,'비만':{'질병관리청 국가건강정보포털 - 비만':'https://health.kdca.go.kr/healthinfo/biz/health/gnrlzHealthInfo/gnrlzHealthInfo/gnrlzHealthInfoView.do?cntnts_sn=5292','질병관리청 국가건강정보포털 - 소아 비만':'https://health.kdca.go.kr/healthinfo/biz/health/gnrlzHealthInfo/gnrlzHealthInfo/gnrlzHealthInfoView.do?cntnts_sn=4008','서울대학교병원 - 비만, 자신에게 맞는 다이어트 선택법':'http://www.snuh.org/health/focus/view.do?seq_no=79',
    '서울대학교병원 - 복부비만':'http://www.snuh.org/health/nMedInfo/nView.do?category=DIS&medid=AA000572','서울대학교병원 - 비만 탈출을 위해선 이 세가지를 함께 해야한다':'http://www.snuh.org/health/tv/view.do?seq_no=186',
    '서울아산병원 - 열량조절식':'https://www.amc.seoul.kr/asan/healthinfo/mealtherapy/mealTherapyDetail.do?mtId=80','대한비만대사학회 - 비만과 수술치료 - 수술 후 체중 유지':'https://www.ksmbs.or.kr/content/general/06.php'}
    ,'식품 알레르기':{'질병관리청 국가건강정보포털 - 알레르기':'https://health.kdca.go.kr/healthinfo/biz/health/gnrlzHealthInfo/gnrlzHealthInfo/gnrlzHealthInfoView.do?cntnts_sn=5806','질병관리청 국가건강정보포털 - 식품 오염':'https://health.kdca.go.kr/healthinfo/biz/health/gnrlzHealthInfo/gnrlzHealthInfo/gnrlzHealthInfoView.do?cntnts_sn=5509',
    '질병관리청 국가건강정보포털 - 알레르기로부터 자유로운 내몸':'https://health.kdca.go.kr/healthinfo/biz/health/ntcnInfo/helthEdcRecsroom/helthEdcRecsroomView.do?phledu_recsroom_sn=1758','서울대학교병원 - 알레르기 치료는 마라톤이다':'http://www.snuh.org/health/tv/view.do?seq_no=81',
    '삼성서울병원 - 음식 알레르기':'http://www.samsunghospital.com/home/healthInfo/content/contenView.do?CONT_SRC_ID=09a4727a8000f302&CONT_SRC=CMS&CONT_ID=981&CONT_CLS_CD=001020001014','삼성서울병원 - 소아 식품 알레르기':'http://www.samsunghospital.com/home/healthInfo/content/contenView.do?CONT_SRC_ID=09a4727a80018c0c&CONT_SRC=CMS&CONT_ID=2487&CONT_CLS_CD=001020002',
    '서울아산병원 - 사망까지 이르는 ‘식품 알레르기’, 어떻게 피할 수 있을까':'https://www.amc.seoul.kr/asan/healthtv/video/videoDetail.do?videoId=4370'}
    ,'고혈압':{'질병관리청 국가건강정보포털 - 고혈압 환자의 식이요법':'https://health.kdca.go.kr/healthinfo/biz/health/gnrlzHealthInfo/gnrlzHealthInfo/gnrlzHealthInfoView.do?cntnts_sn=5999','사단법인 대한 영양사협회 - 고혈압':'https://www.dietitian.or.kr/work/business/kb_c_heart_hypertension.do',
    '서울아산병원 - 고혈압':'https://www.amc.seoul.kr/asan/mobile/healthinfo/mealtherapy/mealTherapyDetail.do?mtId=29','삼성서울병원':'http://www.samsunghospital.com/home/healthInfo/content/contenView.do?CONT_SRC_ID=31885&CONT_SRC=HOMEPAGE&CONT_ID=7137&CONT_CLS_CD=001020002'}};
    
    return(
        <BodyWrapper bg="aboutbg">
            <Header value={
                <LoHeader style={{justifyContent:'space-between'}}>
                    <Title className="logo" value="건강 정보" />
                </LoHeader>} />
            <Wrapper>
                <FlexBox className="column_center mb-5">
                    <UserAfflictionDetail />
                    <AboutTitle>
                        <Title className="medium w_900" value="식이 가이드라인 📚" />
                        {/* <span>더보기</span> */}
                    </AboutTitle>
                        <NewsListWrap>
                            {Object.keys(guideline)?.map((key,index) => (
                               <Accordion defaultActiveKey="0" className='mb-2'>
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>
                                        <NewsListTitle>
                                            <Title className="semibold news_title" value={key} />
                                        </NewsListTitle>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <GuidelineBox>
                                            {/* <Title className="regular text-left mb-3 pre-wrap" value={guideline[key]} /> */}
                                            {Object.keys(guidelinelink[key])?.map((link)=>(
                                                <Title onClick={()=>window.open(guidelinelink[key][link],'_blank')} style={{textDecoration:'1px solid underline #b3b3b3'}} className="light text-left gray" value={link} />
                                            ))}
                                        </GuidelineBox>
                                    </Accordion.Body>
                                </Accordion.Item>
                             </Accordion>
                            ))}    

                        </NewsListWrap>
                </FlexBox>
            </Wrapper>
        <Footer />
        </BodyWrapper>
    )
}
export default About;